import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import callApi from '../../utils/apiCaller';
import ReactToPrint from 'react-to-print';
toast.configure()
class Print extends Component {

  constructor(props) {
    super(props);
    this.state = {
     content:""
     
    }
  }

  async componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
   
    const uid = params.get('uid')
  
  
      const res = await callApi('print-orders/'+uid, 'GET', null, null);
      if (res && res.status === 200) {
        console.log(res.data)
        this.setState({
          content:res.data
        });
      }
    }

  
  render() {

    return (
      <div className="page login-page">
        <div className="container d-flex align-items-center">
          <div className="form-holder has-shadow">
            <div className="row">
           
              <div className="col-lg-6">
                <div className="info d-flex align-items-center">
                  <div className="content">
                    <div className="logo">
                      <h1>IN ẤN</h1>
                    </div>
                    <ReactToPrint
                      trigger={() => {
                        return <a href="#">{this.state.content.length>0?<span style={{color:"yellow"}}>Nhấn để in!</span>:<span style={{color:"white"}}>Đang tải dữ liệu, vui lòng đợi...!</span>}</a>;
                      }}
                      content={() => this.componentRef}
      />
      
                  </div>
                </div>
              </div>
              {/* Form Panel    */}
              <div className="col-lg-6 bg-white">
                <div className="form d-flex align-items-center">
                  <div className="content">
                  {this.state.content.length>0?<div  ref={el => (this.componentRef = el)} dangerouslySetInnerHTML={{__html:this.state.content}} ></div>:null}
                  
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyrights text-center">
          <p>Copyright ©2021 All rights reserved by  <a href="https://ogis.vn" className="external">OGIS DIGITAL CO.,LTD</a>
          </p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Print)