import * as Types from '../../constants/ActionType';
import callApi from '../../utils/apiCaller';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { actShowLoading, actHiddenLoading } from './loading'

export const actFetchPostsRequest = (token, offset) => {
  const newOffset = offset === null || offset === undefined ? 0 : offset;
  const limit = 10;
  return dispatch => {
    dispatch(actShowLoading());
    return new Promise((resolve, reject) => {
      callApi(`posts?limit=${limit}&offset=${newOffset}`, 'GET', null, token)
        .then(res => {
          if (res && res.status === 200) {
            dispatch(actFetchPosts(res.data.results));
            resolve(res.data);
            setTimeout(function(){ dispatch(actHiddenLoading()) }, 200);
          }
        })
        .catch(err => {
          console.log(err);
          reject(err);
          setTimeout(function(){ dispatch(actHiddenLoading()) }, 200);
        });
    });
  };
};

export const actFetchPosts = (posts) => {
  return {
    type: Types.FETCH_POSTS,
    posts
  }
}

export const actDeletePostRequest = (id, token) => {
  return async dispatch => {
    await callApi(`posts/${id}`, 'DELETE', null, token);
    dispatch(actDeletePost(id));
  }
}

export const actDeletePost = (id) => {
  return {
    type: Types.REMOVE_POST,
    id
  }
}

export const actAddPostRequest = (token, data) => {
  return async dispatch => {
    const res = await callApi('posts', 'POST', data, token);
    if (res && res.status === 200) {
      toast.success('Add new Post is success')
      dispatch(actAddPost(res.data));
    }
  }
}

export const actAddPost = (data) => {
  return {
    type: Types.ADD_POST,
    data
  }
}

export const actGetPostRequest = (token, id) => {
  return async dispatch => {
    await callApi(`posts/${id}`, 'GET', null, token);
  };
}

export const actEditPostRequest = (token, id, data) => {
  return async dispatch => {
    const res = await callApi(`posts/${id}`, 'PUT', data, token);
    if (res && res.status === 200) {
      toast.success('Edit Post is success')
      dispatch(actEditPost(res.data));
    }
  }
}

export const actEditPost = (data) => {
  return {
    type: Types.EDIT_POST,
    data
  }
}