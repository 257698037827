import React, { Component } from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { actFetchCategoriesRequest, actDeleteCategoryRequest, actFindCategoriesRequest } from '../../../redux/actions/category';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import MyFooter from '../../MyFooter/MyFooter'
import Paginator from 'react-js-paginator';
import {exportExcel} from '../../../utils/exportExcel'
import ReactDragListView from 'react-drag-listview';
import callApi from '../../../utils/apiCaller';
const MySwal = withReactContent(Swal)

let token;

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      total: 0,
      data:[],
      currentPage: 1,
      isEdit : false
    }
  }
  componentDidMount() {
    this.fetch_reload_data(); //recive data from return promise dispatch
  }

  fetch_reload_data(){
    token = localStorage.getItem('_auth');
    this.props.fetch_categories(token).then(res => {
      this.setState({
        total: res.total,
        data:res.results
      });
    }).catch(err => {
      console.log(err);  
    })
  }

  pageChange(content){
    const limit = 10;
    const offset = limit * (content - 1);
    this.props.fetch_categories(token, offset);
    this.setState({
      currentPage: content
    })
    window.scrollTo(0, 0);
  }

  handleRemove = (id) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(async (result) => {
      if (result.value) {
        await this.props.delete_category(id, token);
       var cloneArray = this.state.data
       var index = cloneArray.findIndex(x => x.id === id);
       cloneArray.splice(index, 1);
       this.setState({
         data:cloneArray
       })
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleUpdatePrority = () => {
  
    var newArray = []
    for (let i = 0; i <this.state.data.length; i++) {
      var item = {}
      item.prority = this.state.data.length-i;
      item.id = this.state.data[i].id;
      newArray.push(item)
    }
    
  
    // var updateArray = newArray.filter(element =>
    //   this.state.data.find(function(post, index) {
       
    //       return post.id === element.id && post.prority !== element.prority;
    //   })
    //    )
    //    console.log(updateArray)
 
    const form = {
      categories:newArray,
           }

    callApi('categories-update-prority', 'PUT',form , token);
  }
  downloadExcel = () => {
    const key = 'categories'
    exportExcel(key)
  }

  render() {

    const {  total } = this.state;
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const data = [...that.state.data];
        
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        that.setState({ data });
        
      },
      nodeSelector: 'tr',
      handleSelector: 'th'
    };
    return (
      <div className="content-inner">
        {/* Page Header*/}
        <header className="page-header">
          <div className="container-fluid">
            <h2 className="no-margin-bottom">Danh mục</h2>
          </div>
        </header>
        {/* Breadcrumb*/}
        <div className="breadcrumb-holder container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Trang chủ</Link></li>
            <li className="breadcrumb-item active">Danh mục sản phẩm</li>
          </ul>
        </div>
        <section className="tables pt-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h3 className="h4">Danh sách danh mục</h3>
                    
                  </div>
                  <div 
                    className="form-inline md-form form-sm mt-0" style={{ justifyContent: 'flex-end', paddingTop: 5, paddingRight: 20 }}>
                    <button onClick={()=>{
                      if (this.state.isEdit){
                        this.handleUpdatePrority()
                      }
                      this.setState({
                        isEdit:!this.state.isEdit
                      })
                    }} 
                    className={this.state.isEdit?"btn btn-primary":"btn btn-secondary"} style={{marginRight:20}}>{this.state.isEdit?"Đồng ý":"Sắp xếp"}</button>
                    <Link to="/categories/add" className="btn btn-primary" > Tạo mới</Link>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      
                        
                       
                        <ReactDragListView {...dragProps}>
                        <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Thứ tự</th>
                            <th>Tên danh mục</th>
                            <th>Mô tả</th>
                            <th>Danh mục con</th>
                            
                            <th style={{ textAlign: "center" }}>Trạng thái</th>
                            <th style={{ textAlign: "center" }}>Hành động</th>
                          </tr>
                        </thead>
                        <tbody>
   
                          {this.state.data && this.state.data.length ? this.state.data.map((item, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">
                                {this.state.isEdit?<Link to={`#`} style={{marginRight:5}}> <i className="fa fa-bars"></i></Link>:null}
                                
                                 {index + 1}
                                  </th>
                                <td>{item.nameCategory}</td>
                                <td>{item.description}</td>
                                <td>{item.subCategory?.length >0? item.subCategory?.map((sub, index) => {
                                  return <Link 
                                   to={`categories/edit/${item.id}?subId=${sub.subId}&subName=${sub.nameCategory}&subImage=${sub.image}&subDescription=${sub.description}`} style={{marginRight:5}}> <i className="fa fa-edit"></i> {sub.nameCategory}<label style={{color:"black"}}>, </label> </Link>
                                  }):null
                                }</td>
                                <td style={{ textAlign: "center" }}>{item.isActive ?
                                  <div className="i-checks">
                                    <input type="checkbox" checked={true} onChange={() => this.handleChangeCheckBox} className="checkbox-template" />
                                  </div>
                                  :
                                  <div className="i-checks">
                                    <input type="checkbox" checked={false} onChange={() => this.handleChangeCheckBox} className="checkbox-template" />
                                  </div>}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <div>
                                  
                                    <span title='Edit' className="fix-action"><Link to={`categories/edit/${item.id}`}> <i className="fa fa-edit"></i></Link></span>
                                     <span title='Delete' onClick={() => this.handleRemove(item.id)} className="fix-action"><Link to="#"> <i className="fa fa-trash" style={{ color: '#ff00008f' }}></i></Link></span>
                                     <span title='Add' className="fix-action"><Link to={`categories/add?parentId=${item.id}&parentName=${item.nameCategory}`}> <i className="fa fa-plus"></i>Thêm danh mục con</Link></span>
 
                                  </div>
                                </td>
                              </tr>
                            )
                          }) : null}
                        
                          </tbody>
                          </table>
                        </ReactDragListView>
                          
                        
                      
                    </div>
                  </div>
                </div>
                <nav aria-label="Page navigation example" style={{ float: "right" }}>
                  <ul className="pagination">
                  <Paginator
                        pageSize={100}
                        totalElements={total}
                        onPageChangeCallback={(e) => {this.pageChange(e)}}
                      />
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/}
        <MyFooter></MyFooter>
      </div >
    )
  }
}

const mapStateToProps = (state) => {
  console.log("xyz")
  return {
    categories: state.categories
  }
}

const mapDispatchToProps = (dispatch) => {
  
  return {
    fetch_categories: (token, offset) => {
      return dispatch(actFetchCategoriesRequest(token, offset))
    },
    delete_category: (id, token) => {
      dispatch(actDeleteCategoryRequest(id, token))
    },
    find_categories: (token, searchText) => {
      return dispatch(actFindCategoriesRequest(token, searchText))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Category)