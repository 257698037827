import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { actToken, actGetNameRole  } from '../../redux/actions/auth'
import { startLoading, doneLoading } from '../../utils/loading'
import $ from 'jquery';
class Header extends Component {

  logOut = async () => {
    localStorage.removeItem("dashBoardId");
    localStorage.removeItem('_auth');
    const token = null;
    startLoading();
    const setToken = this.props.setTokenRedux(token);
    const setRole = this.props.setTokenRoleRedux(token);
    await Promise.all([setToken, setRole])
    doneLoading();
  }
  menuClick = async (e) => {
    e.preventDefault();
    $(this).toggleClass('active');

    $('.side-navbar').toggleClass('shrinked');
    $('.content-inner').toggleClass('active');
    $(document).trigger('sidebarChanged');

    if ($(window).outerWidth() > 1183) {
        if ($('#toggle-btn').hasClass('active')) {
            $('.navbar-header .brand-small').hide();
            $('.navbar-header .brand-big').show();
        } else {
            $('.navbar-header .brand-small').show();
            $('.navbar-header .brand-big').hide();
        }
    }

    if ($(window).outerWidth() < 1183) {
        $('.navbar-header .brand-small').show();
    }
  }
  render() {
    return (
      <header className="header">
        <nav className="navbar">
          {/* Search Box*/}
          <div className="search-box">
            <button className="dismiss"><i className="icon-close" /></button>
            <form id="searchForm" action="#" role="search">
              <input type="search" placeholder="What are you looking for..." className="form-control" />
            </form>
          </div>
          <div className="container-fluid">
            <div className="navbar-holder d-flex align-items-center justify-content-between">
              {/* Navbar Header*/}
              <div className="navbar-header">
 
                  <div onClick = {this.menuClick} id="toggle-btn" className="menu-btn active"><span></span><span></span><span></span>
                    
                  </div>
              </div>
              {/* Navbar Menu */}
              <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
                
                {/* <li className="nav-item dropdown"><a id="languages" rel="nofollow" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link language dropdown-toggle"><img src="https://i.ibb.co/QrtCN5s/GB.png" alt="English" /><span className="d-none d-sm-inline-block">English</span></a>
                  <ul aria-labelledby="languages" className="dropdown-menu">
                    <li><Link rel="nofollow" to="#" className="dropdown-item"> <img src="https://i.ibb.co/QrtCN5s/GB.png" alt="English" className="mr-2" />German</Link></li>
                    <li><Link rel="nofollow" to="#" className="dropdown-item"> <img src="https://i.ibb.co/SnpwbfX/VN.png" alt="English" className="mr-2" />Viet Nam</Link></li>
                  </ul>
                </li> */}
                {/* Logout    */}
                <li className="nav-item"><Link onClick={this.logOut} to="/login" className="nav-link logout"> <span className="d-none d-sm-inline">Đăng Xuất</span><i className="fa fa-sign-out" /></Link></li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    setTokenRedux: (token) => {
      dispatch(actToken(token))
    },
    setTokenRoleRedux: (token) => {
      dispatch(actGetNameRole(token))
    }
  }
}

export default connect(null, mapDispatchToProps)(Header)