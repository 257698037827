import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import Button from "@material-ui/core/Button";

const colors = {
    darkPurple: '#303456',
    red: '#d63a47',
    fanta: '#C24C89',
    pink: '#EA5179',
    lightpink: '#e6a3c5',
    black: '#000000',
    dark: '#4d4d4d',
    grey: '#9c9c9c',
    lightgrey: '#ECF0EF',
    light: '#F7F7F7',
    white: '#FFFFFF',
    peach: '#FF9B6A',
    blue: '#5a9cf5',
    skyblue: '#6adaff',
    green: '#44C7A7',
    leavegreen: '#99CC68'
}
const styles = {
    root: {
        background: 'linear-gradient(45deg, rgba(244,0,87,1) 0%, rgba(121,105,238,1) 100%)',
        color: colors.white
    },
    disabled: {
        background: colors.grey
    }
}

class GradientButton extends React.Component {
    render() {
        const { classes, disabled, children, ...props } = this.props
        return (
            <Button className={classNames(
                classes.root,
                disabled && classes.disabled
            )} variant="contained" disabled={disabled} {...props}>
                { children }
            </Button>
        );
    }
}

export default withStyles(styles)(GradientButton)