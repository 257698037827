import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {connect} from "react-redux";
import classNames from "classnames";
import Box from "@material-ui/core/Box";
import Cart from "../../checkout/Cart";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ProductItem from "../../checkout/ProductItem";
import colors from "../../../constants/colors";
import CheckoutSkeleton from "../../skeleton/CheckoutSkeleton";
import CheckoutDialog from "../../Custom/CheckoutDialog";
import {getProducts,getShopData, createOrder} from "../../../utils/apiCaller";
import RenderIfVisible from 'react-render-if-visible'
import Fullscreen from "react-full-screen";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Button from "@material-ui/core/Button";
import FullscreenBtn from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';

import AddBtn from '@material-ui/icons/Add';
import { toast } from 'react-toastify';
import ReactToPrint from "react-to-print";
import ReceiptPrint from "../../Custom/ReceiptPrint";
import GradientButton from "../../Custom/GradientButton";
import { Alert } from '@material-ui/lab';

const ESTIMATED_ITEM_HEIGHT = 200

const styles = theme => ({
    root: {
       
        height: 'calc(100%)',
        display: 'flex'
    },

    productPanel: {
        width: '65%',
        [theme.breakpoints.down('md')]: {
            width: '55%',
        },
        height: '100%',
        background: colors.white,
        marginRight: 15,
        borderRadius: 20,
    },
    tabs: {
        marginTop: 12
    },
    noProducts: {
        width:'100%',
        textAlign: 'center',
        marginTop: 50
    },
    productGrid: {
        maxHeight: 'calc(100vh - 100px)',
        padding: 15,
        overflow: 'scroll',
        overflowX: 'hidden'
    },
    ReceiptPrintView: {
        maxHeight: 'calc(100vh)',
        padding: 15,
        overflow: 'scroll',
        overflowX: 'hidden'
    },
    hide: {
        display: 'none'
    },
    complete: {
        background: colors.grey,
        color: colors.white
    },
    checkoutPanel: {
        width: "35%",
        [theme.breakpoints.down('md')]: {
            width: "45%",
        },
    }
})

class Transaction extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            searchText:"",
            searchResult:[],
            staff:props.staff,
            isFull: false,
            shopData:{},
            product:[],
            transaction:{},
            submitLoading:false,
            page: 0,
            group: 'category',
            limit: 10,
            tabSelected: 'all',
            checkout: {
                items: [],
                taxAmount: 0,
                discountAmount: 0,
                subtotal: 0,
                grandtotal: 0,
                dialog: false
            },
        }
    }
    goFull = () => {
        this.setState({ isFull: !this.state.isFull });
      }
    componentDidMount() {
        this.fetchData()
     
    }

    fetchData = () => {
        const token = localStorage.getItem('_auth');
        
        getShopData(token).then( shopData => {
            
            this.setState({
                shopData
            })
        } )
        getProducts(token).then( products => {
            this.setState({
                products
                    })
        } )
       
        
    }
   makeid = (length)=> {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
     charactersLength));
       }
       return result.toUpperCase();
    }
    createTransaction = (params) => {
    
       
        const cartlist = params.items
        const subtotal = parseInt(params.subtotal)
        const grandtotal = parseInt(params.grandtotal)
        const discount = parseInt(params.discount) 
        const tax = parseInt(params.tax) 
        const deliveryFee = parseInt(0)
        const status = "Complete"

        const house = "Mua Tại Cửa Hàng"
        const phone = "unknow_phone"
        const email = "unknow@email.com"
        const note = ""
        const fullName = "Khách Vãng Lai"
        
        if (cartlist.length === 0){
            toast.error( "Có lỗi xảy ra. Vui lòng chọn ít nhất 1 sản phẩm" ); 
        }else
        
            {
                var arrayItem = []
                 cartlist.map( ( item, _ ) =>{
                    item.until = new Date()
                   var itemCart =  {
                        "quantity" : parseInt(item.qty, 10),
                        "orderId" : this.makeid(10),
                        "id" : this.makeid(10),
                        "size":"",
                        "color":"none",
                        "price" : parseInt(item.discount>0? item.newPrice:item.price) * item.qty,
                        "unit":item.unit,
                        "productId" : item.id,
                        "product" : item,
                        "nameProduct" : item.name,
                        "sku" : item.sku,
                        "image":item.pictures[0]
                      
                     }
                     arrayItem.push(itemCart)
                 }
                   
                ) 

                var order = {
                    "orderid":this.makeid(10),
                    "address" : {
                        // "province" : {
                        //     name:province.ProvinceName,
                        //     id:parseInt(province.ProvinceID, 10)
                        // } ,
                        // "state":{
                        //     name:state.DistrictName,
                        //     id:parseInt(state.DistrictID, 10)
                        // },
                        // "ward" : {
                        //     name:ward.WardName,
                        //     id:parseInt(ward.WardCode, 10)
                        // },
                        
                        "house" : house,
                        
                      },
                      "phone" : phone ,
                      "status":status,
                      "email":email,
                      "note" : note ,
                      "userId" : 1,
                      "fullName" : fullName ,
                      "itemAmount" : subtotal,
                      "shippingTotal" : deliveryFee,
                      "promoTotal" : discount,
                      "tax":tax,
                      "totalAmount" : grandtotal,
                      "orderDetails":{
                          "results":arrayItem,
                          "total":arrayItem.length
                      }
                      
                   }
                  this.setState({
                    submitLoading:true
                  })
                  const token = localStorage.getItem('_auth');
                   createOrder(order,token).then( results => {
                    
                    if (results.status===200){
                        var res = results.data
                        var dataOrderDetails = res.orderDetails.results
                       
                        const transaction= {
    
                            classes:{
                              paper: {
                              borderRadius: 20,
                              textAlign: 'center',
                              size: 'auto'
                          }},
                          createdat:res.createdat,
                          staff:this.state.staff,
                          customer_name:fullName,
                          customer_address:res.address.house,
                          customer_phone:res.phone,
                          customer_note:res.note,
                             _id:res.orderid,
                            products:dataOrderDetails,
                            subtotal:res.itemAmount.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } ) +"đ",
                            shippingTotal:deliveryFee.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } ) + "đ",
                            discount:res.promoTotal.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } ) + "đ",
                            tax:(res.tax !== undefined?res.tax:0).toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } ) + "đ",
                            grandtotal:res.totalAmount.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } ) + "đ",
                          }
                         
                         this.setState({
                            transaction:transaction,
                            submitLoading:false,
                            dialog: true,
                            checkout: {
                                items: [],
                                taxAmount: 0,
                                discountAmount: 0,
                                subtotal: 0,
                                grandtotal: 0,
                                dialog: false,
                                
                            },
                        })
                        
                        }else{
                           this.setState({
                            submitLoading:false
                           })
                    }
                        } )
                   
            }
            
        
    }
    
    
    
    handleTabs(event, value) {
       
        this.setState({
            tabSelected: value
        })
    }

    renderTabs = () => {
        var categories  = this.state.shopData?.categories!==undefined?this.state.shopData?.categories.categories:[]
        let tabs = [
            {
                _id: 'all',
                name: 'Tất Cả'
            }
        ]
        categories.forEach((item) => {
            tabs.push({ _id: item.id, name: item.nameCategory })
        })

        return tabs.map((item, i) => (
            <Tab key={i} label={item.name} value={item._id}/>
        ))
    }

    renderProducts = () => {
        const { classes} = this.props
        const { tabSelected, checkout } = this.state
     
        if (this.state.products&&this.state.products.length === 0) {
            return (
                <Box className={classes.noProducts}>Chưa có sản phẩm nào.</Box>
            )
        }else{
           
        
        let product = []
        if (this.state.searchText.length>0){
            product.push({
                _id: "all",
                items: [...this.state.searchResult]
            }
            ) 
        }else{
            product.push({
                _id: tabSelected,
                items: tabSelected==="all"?this.state.products.slice().reverse():this.state.products.slice().reverse().filter( p => {
                    let result = false;
                        if (p.categoryId===tabSelected ){
                            result = true;
                           
                        }
                           
                    return result;
                } )
            }
            )
        }
        
   
        return product.map((item, i) => (
            <Box key={i} className={classNames(
                classes.productGrid,
                tabSelected !== item._id && classes.hide
            )}>
                <Grid container spacing={1}>
                    { item.items.map((_product, j) => (
                        <Grid key={j} item lg={3} sm={6}>
                        <RenderIfVisible defaultHeight={ESTIMATED_ITEM_HEIGHT}>
                        
                            <ProductItem
                                onClick={() => this.changeProduct(_product)}
                                selected={checkout.items.find(item => item._id === _product._id)}
                                name={_product.name}
                                discount={_product.discount}
                                price={ _product.price }
                                unit={_product.unit}
                                newPrice={_product.newPrice}
                                image={ _product.pictures[ 0 ] }/>
                          </RenderIfVisible>
                        </Grid>
                      
                    ))}
                </Grid>
            </Box>
        ))
        }
        
}

    calculateTotal = (items) => {
        
        const setting = {
            discount:0,
            tax:0
        }
        let { checkout } = this.state
        let subtotal = 0
        let grandtotal = 0
        let taxAmount = 0
        let discountAmount = 0
        items.forEach(item => {
            subtotal += parseInt(item.discount>0? item.newPrice:item.price) * item.qty
            grandtotal += parseInt(item.discount>0? item.newPrice:item.price) * item.qty
        })
       
        if (parseInt(setting.tax) !== 0) {
            taxAmount = ((setting.tax/100)*grandtotal).toFixed(2)
            grandtotal = parseFloat(grandtotal) + parseFloat(taxAmount)
        }
        if (parseInt(setting.discount) !== 0) {
            discountAmount = ((setting.discount/100)*grandtotal).toFixed(2)
            grandtotal = grandtotal - parseFloat(discountAmount)
        }
        checkout = {
            ...checkout,
            subtotal,
            taxAmount,
            discountAmount,
            grandtotal: grandtotal.toFixed(2)
        }
        this.setState({
            checkout
        })
    }
    newProduct = () =>{
        const id_ = this.makeid(10)
        const categoryId_ = this.makeid(10)
        const unit_ = this.makeid(2)
        var p = {
            "typeProduct":"general",
            "sku" : "SKU-"+this.makeid(10),
            "subCategoryId" : "",
            "brands" : [
              ""
            ],
            "shortDesc" : "",
            "smPictures" : [
                "https://ogis.vn/images/home/logo.png"  ],
            "categoryName" : this.makeid(10),
            "vid" : 212,
            "newPrice" : 0,
            "ratings" : 0,
            "_id" : id_,
            "stock" : 1999,
            "new" : false,
            "category" : [
                this.makeid(10),
              ""
            ],
            "top" : false,
            "discount" : false,
            "name" : this.makeid(10),
            "subCategoryName" : "",
            "reviews" : 0,
            "size" : null,
            "fPrices" : [
              {
                "price" : 0,
                "unit" : unit_
              }
            ],
            "id" : id_,
            "unit" : unit_,
            "pictures" : [
              "https://ogis.vn/images/home/logo.png"
            ],
            "salePrice" : 0,
            "until" : "1-01-01T23:59:59",
            "price" : 0,
            "variants" : null,
            "categoryId" : categoryId_
          }
          this.changeProduct(p)
    }
    changeProduct = (product) => {
       
        let { checkout } = this.state
        product.qty = 1
        let products = [...checkout.items, product]
        if (checkout.items.find(item => item._id === product._id)) {
            products = checkout.items.filter(item => item._id !== product._id)
        }
        checkout = { ...checkout, items: products }
        this.setState({
            checkout
        }, () => {
            this.calculateTotal(products)
        })
    }
    changeName = (id, name) => {
        let { checkout } = this.state
        const { items } = checkout
        let product = items.findIndex(item => item._id === id)
        if (product !== -1) {
            items[product].name = name
        }
        checkout.items = items
        
        this.setState({
            checkout
        }, () => {
           
        })
    }
    changePrice = (id, price) => {
        let { checkout } = this.state
        const { items } = checkout
        let product = items.findIndex(item => item._id === id)
        if (product !== -1) {
            items[product].price = price
            items[product].fPrices[0].price = price
        }
        checkout.items = items
        this.setState({
            checkout
        }, () => {
            this.calculateTotal(items)
        })
    }
    changeQty = (id, qty) => {
        let { checkout } = this.state
        const { items } = checkout
        let product = items.findIndex(item => item._id === id)
        if (product !== -1) {
            if (qty === 0) {
                items.splice(product, 1)
            } else {
                items[product].qty = qty
            }
        }
        checkout.items = items
        this.setState({
            checkout
        }, () => {
            this.calculateTotal(items)
        })
    }

    deleteItem = (id) => {
        let { checkout } = this.state
        const { items } = checkout
        let product = items.findIndex(item => item._id === id)
        if (product !== -1) {
            items.splice(product, 1)
        }
        checkout.items = items
        this.setState({
            checkout
        }, () => {
            this.calculateTotal(items)
        })
    }

    pay = () => {
        const { checkout } = this.state
        const { items, subtotal, discountAmount,taxAmount, grandtotal } = checkout
        let params = { items, subtotal, grandtotal }
        params.discount = discountAmount
        params.tax = taxAmount

        this.createTransaction(params)
    }

    cancelCheckout = () => {
        this.setState({
            checkout: {
                items: [],
                taxAmount: 0,
                discountAmount: 0,
                subtotal: 0,
                grandtotal: 0,
                dialog: false
            },
        })
    }

    closeDialog = () => {
        this.setState({
            checkout: {
                items: [],
                discount: 10,
                discountAmount: 0,
                subtotal: 0,
                grandtotal: 0,
                dialog: false
            },
        })
    }
     handleOnSelect = (product) => {
        let { checkout } = this.state
        if (checkout.items.find(item => item._id === product._id)) {
            
        }else{
            this.changeProduct(product)
        }
        
       
       
      }
      printOrder = () => {
       this.setState({
           dialog:false,
           isFull:false
       })
       
       
      }
     
      completeClick = () =>{
        this.setState({
           
            dialog:false,
        })
      }
      
       formatResult = (item) => {
        return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
      }
       autocompleteMatch(search_terms,input) {
           input = input.toLowerCase()
        var reg = new RegExp(input)
        return search_terms.filter(x => ((x.name.toLowerCase().match(reg))||x.sku.toLowerCase().match(reg)));
      }
       
      handleChangeSearch = (event) => {
        const target = event.target;
        const value = target.value;
        const arrSearch = this.autocompleteMatch(this.state.products,value)
        console.log(arrSearch)
        this.setState({
            searchText: value,
            searchResult:arrSearch
        });
      }
    render() {
        const { tabSelected, checkout ,submitLoading,transaction,staff} = this.state
        const { items, subtotal, discountAmount, taxAmount, tax,grandtotal } = checkout
        const { classes } = this.props
        

        if (this.state.products === undefined|| this.state.products.length===0) return <CheckoutSkeleton/>

        return (
            
            <div>
            
                
            <Fullscreen
          enabled={this.state.isFull}
          onChange={isFull => this.setState({isFull})}
        >
       
      
            
                <Box className={classes.root} >
                
                    {/* <CheckoutDialog
                        open={dialog}
                        { ...transaction }
                        onClose={this.closeDialog}
                    /> */}

                    <Box className={classes.productPanel}>
                    <div style={{
                    maxWidth: 1200,
                    marginLeft: 0,
                    width: "100%",
                    marginTop: "1.0rem",
                    position: "relative",
                    display: "flex",
                    alignItems: "center"
                   
            }}>
                    <div style={{
                            maxWidth: 570,
                            marginLeft: 15,
                            width: "100%",
                            
                        
                    }}>
                    <input type="search"
                    value={this.state.searchText}
                     className="form-control"
                      name="q"
                       id="q"
                       onChange={this.handleChangeSearch}
                        placeholder="Nhập SKU hoặc tên sản phẩm bạn muốn tìm"
                        style={
                                    {
                                        height: "42px",
                                    zIndex: "9",	
                                border: "1px solid #f40057",
                                
                                backgroundColor: "white",
                                boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
                                hoverBackgroundColor: "#eee",
                                color: "#212121",
                                fontSize: "16px",
                                fontFamily: "Arial",
                                iconColor: "grey",
                                lineColor: "rgb(232, 234, 237)",
                                placeholderColor: "grey",
                                clearIconMargin: '3px 14px 0 0',
                                searchIconMargin: '0 0 0 16px'
                            }} />
                               
                        
                             </div>
                            <Button
                                
                                variant="contained"
                                style={{
                                        minWidth:"64px",
                                        marginLeft:15,
                                        height: "44px",
                                        background: colors.fanta,
                                        color: colors.white
                                    }}

                                startIcon={<AddBtn/>}
                                onClick={this.newProduct}>
                                Thêm Sản Phẩm
                            </Button>
                           <Button
                                
                                variant="contained"
                                style={{
                                        marginLeft:15,
                                        height: "44px",
                                        minWidth:"44px",
                                        background: colors.grey,
                                        color: colors.white
                                    }}

                            
                                onClick={this.goFull}>
                                {this.state.isFull?<FullscreenExit/>:<FullscreenBtn/>}
                                
                            </Button>
                           
            </div>
                        {this.state.isFull?<Paper elevation={0}>
                            <Tabs
                                className={classes.tabs}
                                value={tabSelected}
                                onChange={(event, value) => this.handleTabs(event, value)}
                                scrollButtons="auto"
                                variant="scrollable">
                                { this.renderTabs() }
                            </Tabs>
                        </Paper>:null}
                        { this.state.products?this.renderProducts():null }
                    </Box>
                    <Box className={classes.checkoutPanel}>
                    
                    {this.state.dialog?
                        <div className={classNames(
                classes.ReceiptPrintView,
                
            )}>
                        <ReceiptPrint
                            
                            staff={staff}
                            customer_name={transaction.customer_name}
                            customer_address={transaction.customer_address}
                            customer_phone={transaction.customer_phone}
                            customer_note={transaction.customer_note}
                            createdat={transaction.createdat}
                            tax={transaction.tax}
                            id={transaction._id}
                            products={transaction.products}
                            subtotal={transaction.subtotal}
                            discount={transaction.discount}
                            grandtotal={transaction.grandtotal}
                            ref={el => (this.componentRef = el)} />
                        
                        <Grid container spacing={3}>
                        <Grid item xs={6} lg={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                className={classes.complete}
                                // disabled={items.length === 0 || disabled?true:false}
                                // startIcon={<CancelIcon/>}
                                onClick={this.completeClick}
                                >
                                HOÀN THÀNH
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <Box className={classes.button}>
                                        <GradientButton fullWidth onClick={this.printOrder}>
                                            <faFingerprint/>
                                            In Đơn Hàng
                                        </GradientButton>
                                    </Box>
                                )
                            }}
                            pageStyle='@page { size: auto; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 40px !important; } }'
                            documentTitle={transaction._id}
                            content={() => this.componentRef}
                        />
                        </Grid>
                    </Grid>
                    </div>:
                    <Cart
                            items={items}
                            subtotal={subtotal}
                            tax={taxAmount}
                            discount={discountAmount}
                            grandtotal={grandtotal}
                            changeQty={this.changeQty}
                            onChangePrice={this.changePrice}
                            onChangeName={this.changeName}
                            deleteItem={this.deleteItem}
                            payClick={this.pay}
                            cancelClick={this.cancelCheckout}
                            disabled={submitLoading}
                            
                        />}
                        
                    </Box>
                </Box>
                </Fullscreen>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    if (state.infoMe?.role && state.infoMe?.role?.description){
     return {
       staff:state.infoMe?.role?.description.toUpperCase() +" "+ state.infoMe?.name.toUpperCase()
    }
   }
   
 }

const mapDispatchToProps = {
   
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Transaction))