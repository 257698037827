import React, { Component } from 'react'
import MyFooter from '../../MyFooter/MyFooter'
import { connect } from 'react-redux'
import callApi from '../../../utils/apiCaller';
import { actAddUserRequest, actGetUserRequest, actEditUserRequest } from '../../../redux/actions/user';
import { Redirect } from 'react-router-dom';
import { uploadS3 } from '../../../utils/upload'
import { css } from '@emotion/core';
import ReactQuill from 'react-quill';
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt,faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { TwitterPicker } from 'react-color'
let token;
let id;
let typeParam;

const override = css`
    display: block;
    margin: 0 auto;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
`;
class ActionUserShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      address: '',
      email: '',
      phone: '',
      pageId: '',
      dashBoardId:'',
      org:'',
      membership:0,
      googleAnalyticId:"",
      domain: '',
      subdomain: '',
      logoText:'',
      logoSlogan:'',
      aboutShort: '',
      aboutLong: '',
      feature: '',
      shopGuide:"",
      places: [{
        "name":"",
        "address": "",
        "phone": "",
        "weekday_":"",
        "image":"http://via.placeholder.com/620x300",
        "imageTmp":null,
      }],
      introBanners:[{
        image:"http://via.placeholder.com/1668x629",
        title:"",
        aSubtitle:"",
        titleColor:"ffffff",
        aSubtitleColor:"ffffff",
        subImage:"",
        subClass:"position-right",
        adClass:"text-left",
        area:"",
        link:""
      }],
      logoLink: "",
      logo: null,
      faviconLink: "",
      favicon: null,
      

      introBannerTitle:'',
      introBannerASubTitle:'',
      introBannerImage:null,
      introBannerImageLink:'',
      introBannerSubImage:null,
      introBannerSubImageLink:'',

      bannerBotom:null,
      bannerBotomLink:'',
      bannerBotomUrl:'',
      bottomBannerTitle:'',
      bottomBannerASubTitle:'',
      bottomBannerDescription:'',

      introBaner:{},
      templates:[],
      template:{},
      serviceRefund:'',
      serviceShip:'',
      serviceSupport:'',
      emailNotifi:"",
      keyworld:"",
      redirectToUser: false,
      loading: false
    };
    id = this.props.id
    typeParam = this.props.typeParam
    
  }
  async componentDidMount() {
    token = localStorage.getItem('_auth');
    if (id) {
      this.setState({
        loading: true
      })
      const response = await callApi(`users/${id}`, 'GET', null, token);
      const res = response.data?.ShopData
      let _introBaner =res?.introBanners!=null?res?.introBanners[0]:[]
    
      this.setState({
        domain:response.data?.domain,
        subdomain:response.data?.subdomain,
        // templates:response.data?.templates,
        name: res?.name,
        address: res?.address,
        email: res?.email,
        phone: res?.phone,
        pageId: res?.pageId,
        dashBoardId:res?.dashBoardId,
        org:res?.org,
        membership:res?.membership,
        googleAnalyticId:res?.googleAnalyticId,
        keyworld:res?.keyworld,
        emailNotifi:res?.emailNotifi,
        logoText:res?.logoText,
        logoSlogan:res?.logoSlogan,
        aboutShort: res?.aboutShort,
        aboutLong: res?.aboutLong,
        feature: res?.feature,
        logoLink: res?.logoTop,
        faviconLink: res?.favicon,
        places:res?.places?res.places:[{
          "name":"",
          "address": "",
          "phone": "",
          "weekday_":"",
          "image":"http://via.placeholder.com/620x300",
          "imageTmp":null
          
        }],
        introBanners:res?.introBanners?res.introBanners:[{
          image:"http://via.placeholder.com/1668x629",
          title:"",
          aSubtitle:"",
          subImage:"",
          titleColor:"ffffff",
          aSubtitleColor:"ffffff",
          subClass:"position-right",
          adClass:"text-left",
          area:"",
          link:"",
        }],
        bannerBotomLink:res?.botomBanner?.image,
        bannerBotomUrl:res?.botomBanner.link,
        bottomBannerTitle:res?.botomBanner?.title,
        bottomBannerASubTitle:res?.botomBanner?.aSubtitle,
        bottomBannerDescription:res?.botomBanner?.description,
        introBaner:_introBaner,
        template:res?.template,
        
        introBannerTitle:_introBaner.title,
        introBannerASubTitle:_introBaner.aSubtitle,
        introBannerImageLink:_introBaner.image,
        introBannerSubImageLink:_introBaner.subImage,
        serviceShip:res?.services!= null&&res?.services.length>1?res?.services[0].subtitle:"",
        serviceRefund: res?.services!= null&&res?.services.length>0?res?.services[1].subtitle:"",
        
        serviceSupport:res?.services!= null&&res?.services.length>2?res?.services[2].subtitle:"",
        shopGuide:res?.shopGuide,
        loading: false
        
      })
    }
    if (typeParam==="shop_banner"){
     const t = await callApi(`templates`, 'GET', null, null);
     
     this.setState({
       "templates":t.data
     })
    }
  }

  handleChangeImage = (event) => {
    const target = event.target;
    if (target.files[0]) {
      const img = target.files[0];
      const name = target.name.replace("Link", "");;
      this.setState({
        [name]: img
      });
      const output = document.getElementById(target.name);
      output.src = URL.createObjectURL(target.files[0]);
    }
    
  }
  handleChangeBottomBannerDescription = (value) => {
    this.setState({
      bottomBannerDescription: value
    });
  }
   handleChangeAboutShort = (value) => {
    this.setState({
      aboutShort: value
    });
  }
  handleChangeShopGuide = (value) => {
    this.setState({
      shopGuide: value
    });
  }
  
  handleChangeAboutLong = (value) => {
    this.setState({
      aboutLong: value
    });
  }
  handleChangeKeyworld = (event) => {
    this.setState({
      keyworld: event.target.value
    });
  }
  
  handleChangeFeature = (value) => {
    this.setState({
      feature: value
    });
  }
 
  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
   
    this.setState({
      [name]: value
    });
  }
  
  handleChangeTemplate = (event) => {
    const target = event.target;
    const idTemplate = target.value;
    const s = this.state.templates.find(x => x.id === idTemplate);
    
    this.setState(
      {
        template:s
      }
    );
  }
  handleChangeMemberType = (event) => {
    const target = event.target;
 
    this.setState(
      {
        membership:target.value
      }
    );
  }

  callSubmit = async () => {
    // upload image to firebase
    let thelogoLink = this.state.logoLink+"?v="+Math.random()
    let thefaviconLink = this.state.faviconLink+"?v="+Math.random()
    let theBannerBotomLink = this.state.bannerBotomLink+"?v="+Math.random()
    let places = [...this.state.places]
    let introBanners = [...this.state.introBanners]
    try {
      for (let i = 0; i < places.length; i++) {
        var place = places[i]
        if (place.image.indexOf("blob:")===0){
          try {
            const linkK = await uploadS3("placeImage"+i,place.imageTmp,token);
           
            place.image = linkK+"?v="+Math.random();
            place.imageTmp = ""
            places[i] = place
          } catch (error) {
            console.log("error "+JSON.stringify(error))
          }
           
        }
      }
      for (let i = 0; i < introBanners.length; i++) {
        var banner = introBanners[i]
        
        if (banner.subImage.indexOf("blob:")===0){
          try {
            const linkK = await uploadS3("bannerSubImage"+i,banner.subImageTmp,token);
            
            banner.subImage = linkK+"?v="+Math.random();
            banner.subImageTmp = ""
            introBanners[i] = banner
          } catch (error) {
            console.log("error "+JSON.stringify(error))
          }
           
        }
        
        if (banner.image.indexOf("blob:")===0){
          try {
            const linkK = await uploadS3("bannerImage"+i,banner.imageTmp,token);
            
            banner.image = linkK+"?v="+Math.random();
            banner.imageTmp = ""
            introBanners[i] = banner
          } catch (error) {
            console.log("error "+JSON.stringify(error))
          }
           
        }
      }
      if (this.state.logo !== null) {
        
       thelogoLink = await uploadS3("logoshop",this.state.logo,token);
  
      }
      if (this.state.favicon !== null) {
        
        thefaviconLink = await uploadS3("faviconshop",this.state.favicon,token);
   
       }
      
      if (this.state.bannerBotom !== null) {
       
        theBannerBotomLink = await uploadS3("shopbottombanner",this.state.bannerBotom,token);
       
       }
       
     
      if (id !== undefined && id !== null && id.length > 0) {
       
        const newName = (this.state.name === '') ? null : this.state.name
        const newAddress = (this.state.address === '') ? null : this.state.address
        const newPhone = (this.state.phone === '') ? null : this.state.phone
        const newPageId = (this.state.pageId === '') ? null : this.state.pageId
        const newDashBoardId = (this.state.dashBoardId === '') ? null : this.state.dashBoardId
        const newOrg = (this.state.org === '') ? null : this.state.org
        
        
        const newGoogleAnalyticId = (this.state.googleAnalyticId === '') ? null : this.state.googleAnalyticId
       
        
        const newEmailNotifi = (this.state.emailNotifi === '') ? null : this.state.emailNotifi
        const newKeyworld = (this.state.keyworld === '') ? null : this.state.keyworld
        const newEmail = (this.state.email === '') ? null : this.state.email
      
        const editUser = {
          name: newName,
          address: newAddress,
          phone: newPhone,
          pageId:newPageId,
          dashBoardId:newDashBoardId,
          org:newOrg,
          membership:parseInt(this.state.membership),
          googleAnalyticId:newGoogleAnalyticId,
          emailNotifi:newEmailNotifi,
          email:newEmail,
          logoTop:thelogoLink,
          favicon:thefaviconLink,
          logoFooter:thelogoLink,
          logoText:this.state.logoText,
          logoSlogan:this.state.logoSlogan,
          keyworld:newKeyworld,
          // bannerBotom:theBannerBotomLink,
          botomBanner:{
            image:theBannerBotomLink,
            link:this.state.bannerBotomUrl,
            title:this.state.bottomBannerTitle,
            aSubtitle:this.state.bottomBannerASubTitle,
            description:this.state.bottomBannerDescription,
      
          },
          introBanners:introBanners,
          template:this.state.template,
          aboutShort:this.state.aboutShort,
          aboutLong:this.state.aboutLong,
          feature:this.state.feature,
          shopGuide:this.state.shopGuide,
          places:places,
          services:[
            {id:0,
            icon:"icon-rocket",
            title:"Thanh toán & Vận chuyển",
            subtitle:this.state.serviceShip
            },
            {id:1,
              icon:"icon-rotate-left",
              title:"Đổi trả & Hoàn tiền",
              subtitle:this.state.serviceRefund
              },
              {id:2,
                icon:"icon-life-ring",
                title:"Hỗ trợ",
                subtitle:this.state.serviceSupport
                }]
        }
        // console.log("editUser "+JSON.stringify(editUser))
        editUser.type = "shop-data"
        
        await this.props.edit_user(token, id, editUser);
        
      }
    } catch (error) {
      console.log(JSON.stringify(error))
    }
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      loading: true
    })
    await this.callSubmit()
    this.setState({
      loading: false,
      redirectToUser: false
    })
  }
  removeBanner = (index)=>{
    var copy = this.state.introBanners.slice()

    if (copy.length <2)
    {
      alert("Phải có tối thiểu 1 Banner.")
    }else{
      copy.splice(index, 1);
    this.setState({
      introBanners: [...copy]
      }) 
    }
    
  }
  handleAddBanner = () => {
    if(this.state.introBanners.length>6){
      alert("Chỉ được thêm tối đa 6 Banner khác nhau")
    }else{
      let p = [...this.state.introBanners]
      p.push({
        image:"http://via.placeholder.com/1668x629",
        title:"",
        aSubtitle:"",
        subImage:"",
        subClass:"position-right",
        adClass:"text-left",
        area:"",
        link:""
      })
      this.setState({
        introBanners: p
      }) 
    }
  };
  handleChangeColorTextBanner = (name) => (color) => { 
    const value = color.hex;

    const myArr = name.split("-|-");
    const index = parseInt(myArr[1],10)
    var copy = this.state.introBanners.slice()
    
    copy[index][myArr[0]] = value
  
    this.setState({
      introBanners: [...copy]
    });
  };
  handleChangeBanner = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const myArr = name.split("-|-");
    const index = parseInt(myArr[1],10)
    var copy = this.state.introBanners.slice()
    
    copy[index][myArr[0]] = value
    
    this.setState({
      introBanners: [...copy]
    });
  }
  handleChangePlaceName= (event) => {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    const myArr = name.split("-|-");
    const index = parseInt(myArr[1],10)
    var copy = this.state.places.slice()
    copy[index] = {
      "name":value,
      "address": copy[index].address,
      "phone": copy[index].phone,
      "weekday_text":copy[index].weekday_text,
      "image":copy[index].image,
      "imageTmp":copy[index].imageTmp,
    }
    this.setState({
      places:[...copy]
    }) 
  }
  handleChangePlacePhone= (event) => {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    const myArr = name.split("-|-");
    const index = parseInt(myArr[1],10)
    var copy = this.state.places.slice()
    copy[index] = {
      "name":copy[index].name,
      "address": copy[index].address,
      "phone": value,
      "weekday_text":copy[index].weekday_text,
      "image":copy[index].image,
      "imageTmp":copy[index].imageTmp,
    }
    this.setState({
      places:[...copy]
    }) 
  }
  handleChangePlaceAddress= (event) => {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    const myArr = name.split("-|-");
    const index = parseInt(myArr[1],10)
    var copy = this.state.places.slice()
    copy[index] = {
      "name":copy[index].name,
      "address": value,
      "phone": copy[index].phone,
      "weekday_text":copy[index].weekday_text,
      "image":copy[index].image,
      "imageTmp":copy[index].imageTmp,
    }
    this.setState({
      places:[...copy]
    }) 
  }
  handleChangePlaceWeekDay= (value, index) => {
    var copy = this.state.places.slice()
    copy[index] = {
      "name":copy[index].name,
      "address": copy[index].address,
      "phone": copy[index].phone,
      "weekday_text":value,
      "image":copy[index].image,
      "imageTmp":copy[index].imageTmp,
    }
    this.setState({
      places:[...copy]
    }) 
  }
  handleChangePlaceImage = (event) => {
    const target = event.target;
    if (target.files[0]) {
      const img = target.files[0];
      var copy = this.state.places.slice()
      const name = target.name;
      const myArr = name.split("-|-");
      const index = parseInt(myArr[1],10)
        copy[index] = {
          "name":copy[index].name,
          "address": copy[index].address,
          "phone": copy[index].phone,
          "weekday_text":copy[index].weekday_text,
          "image":URL.createObjectURL(target.files[0]),
          "imageTmp":img
        }
        this.setState({
          places:[...copy]
        }) 
    }
  }

  handleChangeBannerImage = (event) => {
    const target = event.target;
    if (target.files[0]) {
      const img = target.files[0];
      const name = target.name;
      const myArr = name.split("-|-");
      const index = parseInt(myArr[1],10)
      var copy = this.state.introBanners.slice()
    
    copy[index][myArr[0]] = URL.createObjectURL(target.files[0])
    copy[index][myArr[0]+"Tmp"] = img

    this.setState({
      introBanners: [...copy]
    });
    }
  }
  

  removePlace = (index)=>{
    var copy = this.state.places.slice()

    if (copy.length <2)
    {
      alert("Phải có tối thiểu 1 vị trí.")
    }else{
      copy.splice(index, 1);
    this.setState({
      places: [...copy]
      }) 
    }
    
  }
  
 
  handleAddPlace = () => {
    if(this.state.places.length>6){
      alert("Chỉ được thêm tối đa 6 vị trí khác nhau")
    }else{
      let p = [...this.state.places]
      p.push({
      
          "name":"",
          "address": "",
          "phone": "",
          "weekday_":"",
          "image":"http://via.placeholder.com/620x300",
        })
      this.setState({
        places: p
      }) 
    }
  };
  
  render() {
    const { name, address,phone,pageId,dashBoardId,org,membership,googleAnalyticId, email,domain,subdomain,emailNotifi,
      aboutShort = "", aboutLong = "",feature = "",shopGuide="",
      bottomBannerTitle='',
      bottomBannerASubTitle='',
      bottomBannerDescription='',
       logoLink,logoText,logoSlogan,
       faviconLink,
       bannerBotomUrl,
       bannerBotomLink,
       serviceShip,serviceRefund,serviceSupport,
       redirectToUser, loading } = this.state;
    if (redirectToUser) {
      return <Redirect to="/"></Redirect>
    }
    const modules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
      ],
    };
  
    const formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ];

    
    return (
      <div className="content-inner">
        {/* Page Header*/}
        <div className='sweet-loading'>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={30}
            color={'#796aeebd'}
            loading={loading}
          />
        </div>
        <header className="page-header">
          <div className="container-fluid">
            <h2 className="no-margin-bottom">Quản Lý Website</h2>
          </div>
        </header>
        {/* Breadcrumb*/}
        <div className="breadcrumb-holder container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Trang chủ</a></li>
            <li className="breadcrumb-item active">Quản Lý Website</li>
          </ul>
          {typeParam==="shop_infor"||typeParam==="shop_about"||typeParam==="seo_top_google"?<label className="col-sm-12 form-control-label" style={{color:"red"}}>SEO - Các thông tin bên dưới sẽ hiển thị trong kết quả tìm kiếm của công cụ tìm kiếm (Google, Bing,...). Sử dụng đoạn văn mô tả và từ khóa để cải thiện thứ tự xếp hạng cho website của bạn </label>:null}
        </div>
        {/* Forms Section*/}
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              {/* Form Elements */}
              <div className="col-lg-12">
                <div className="card">
               

                  
                      
                  <div className="card-body">
                 
                    <form className="form-horizontal" onSubmit={(event) => this.handleSubmit(event)}>
                <div style={{display: typeParam!=="shop_infor"?"none":""}}>
                <h3 style={{marginBottom:20}}>Thông tin Cửa hàng/ Siêu thị</h3>
                  <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Tên miền riêng</label>
                        <div className="col-sm-3">
                          <input disabled type="text" placeholder="thoitrangtre.com" onChange={this.handleChange} name="domain" value={domain} className="form-control" />
                        </div>
                        <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Tên miền OGIS</label>
                        <div className="col-sm-3">
                          <input disabled type="text" placeholder="thoitrang" onChange={this.handleChange} name="subdomain" value={subdomain} className="form-control" />
  
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Tên Cửa hàng</label>
                        <div className="col-sm-3">
                          <input type="text" onChange={this.handleChange} name="name" value={name} className="form-control" />
                        </div>
                        <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Địa chỉ</label>
                        <div className="col-sm-3">
                          <input type="text" onChange={this.handleChange} name="address" value={address} className="form-control" />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Số điện thoại</label>
                        <div className="col-sm-3">
                          <input type="text" onChange={this.handleChange} name="phone" value={phone} className="form-control" />
                        </div>
                        <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Email</label>
                        <div className="col-sm-3">
                          {
                            id ? <input type="email" onChange={this.handleChange} name="email" value={email} disabled className="form-control" />
                              : <input type="email" onChange={this.handleChange} name="email" value={email} className="form-control" />
                          }
                        </div>
                      </div>

                      <div className="form-group row">
                      <label className="col-sm-3 form-control-label">Danh sách email nhận thông báo có đơn hàng mới (Tối đa 2 email, cách nhau bởi dấu ,)</label>
                        <div className="col-sm-3">
                          <input type="text" onChange={this.handleChange} name="emailNotifi" value={emailNotifi} className="form-control" />
                        </div>
                        <label className="col-sm-3 form-control-label">PageId Message Facebook</label>
                        <div className="col-sm-3">
                          <input type="text" onChange={this.handleChange} name="pageId" value={pageId} className="form-control" />
                        </div>
                        
                        
                      </div>

                      <div className="form-group row">
                      <label className="col-sm-3 form-control-label">DashboardId</label>
                       
    
                        <div className="col-sm-3">
                          <input type="text" onChange={this.handleChange} name="dashBoardId" value={dashBoardId} className="form-control" />
                        </div>
                        <label className="col-sm-3 form-control-label">GoogleAnalyticId</label>
                       
    
                       <div className="col-sm-3">
                         <input type="text" onChange={this.handleChange} name="googleAnalyticId" value={googleAnalyticId} className="form-control" />
                       </div>
                        
                        
                      </div>
                      
                      <div className="form-group row">
                      <label className="col-sm-3 form-control-label">Link Chứng Nhận Bộ Công Thương</label>
                       
    
                        <div className="col-sm-9">
                          <input type="text" onChange={this.handleChange} name="org" value={org} className="form-control" />
                        </div>
                        
                        
                        
                      </div>

                      
                      <div className="align-items-center">
                      <div className="line" />
                        <h3 style={{marginBottom:20}}>Logo</h3>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="fileInput" className="col-sm-3 form-control-label">Hình ảnh logo</label>
                        <div className="col-sm-9">
                          <input type="file" onChange={this.handleChangeImage} name="logoLink" className="form-control-file" />
                          <div className="fix-cart">
                          <img src={logoLink || 'http://via.placeholder.com/400x300'} id="logoLink" className="fix-img" alt="logoLink" />
                          </div>                     
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="fileInput" className="col-sm-3 form-control-label">Favicon</label>
                        <div className="col-sm-9">
                          <input type="file" onChange={this.handleChangeImage} name="faviconLink" className="form-control-file" />
                          <div className="fix-cart">
                          <img src={faviconLink || 'http://via.placeholder.com/96x96'} id="faviconLink" className="fix-img" alt="faviconLink" />
                          </div>                     
                        </div>
                      </div>
                      <span>Nếu bạn không có hình Logo thì có thể dùng chữ theo gợi ý bên dưới </span>
                      <div style={{height:30}} />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Tên Cửa hàng ngắn gọn (tối đa 9 ký tự)</label>
                        <div className="col-sm-3">
                          <input placeholder="Vanis Shop" type="text" onChange={this.handleChange} name="logoText" value={logoText} className="form-control" />
                        </div>
                        <label className="col-sm-3 form-control-label" >Slogan Logo Cửa hàng ngắn gọn (tối đa 15 ký tự)</label>
                        <div className="col-sm-3">
                        <input type="text" placeholder="Thời Trang Trẻ" onChange={this.handleChange} name="logoSlogan" value={logoSlogan}  className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <h3 style={{marginBottom:20}}>Hệ thống cửa hàng *</h3>
                      
                      {this.state.places.length>0 && this.state.places.map( ( item, i ) =>{
                              return <div key={  '-i' + i } style={{marginLeft:20}}>
                              <div className="form-group row">
                              <h3 className="col-sm-3 form-control-label">Cửa hàng {i+1}<sup> <FontAwesomeIcon 
                                      onClick={this.removePlace.bind(this,i)}
                                      color= 'Tomato' icon={faTrashAlt} 
                                      style={{ marginTop:10,cursor: "pointer"}}/>
                                      </sup></h3>
                              </div>
                              <div className="form-group row">
                                      <label className="col-sm-3 form-control-label">Tên địa điểm</label>
                                      <div className="col-sm-9">
                                        <input name={"name-|-"+i} onChange={this.handleChangePlaceName} value={item.name} type="text" className="form-control" />
                                      </div>
                              </div>
                              <div className="form-group row">
                                      <label className="col-sm-3 form-control-label">Số điện thoại</label>
                                      <div className="col-sm-9">
                                        <input name={"phone-|-"+i} onChange={this.handleChangePlacePhone} value={item.phone} type="text" className="form-control" />
                                      </div>
                              </div>
                              <div className="form-group row">
                                      <label className="col-sm-3 form-control-label">Địa chỉ</label>
                                      <div className="col-sm-9">
                                        <input name={"address-|-"+i} onChange={this.handleChangePlaceAddress} value={item.address} type="text" className="form-control" />
                                      </div>
                              </div>
                              <div className="form-group row">
                                      <label className="col-sm-3 form-control-label">Lịch làm việc</label>
                                      <div className="col-sm-9">
                                      <ReactQuill
                                        name = "aboutShort"
                                        modules={modules}
                                        formats={formats}
                                        value={item.weekday_text?item.weekday_text:""}
                                        onChange={(value) => {
                                          this.handleChangePlaceWeekDay(value,i)
                                        }} />
                                    </div>
                              </div>
                              <div className="form-group row">     
                                  <label htmlFor="fileInput" className="col-sm-3 form-control-label">Hình ảnh</label>
                                      <div className="col-sm-9" style={{marginTop:15}}>
                                        <input type="file" onChange={this.handleChangePlaceImage} name={"placeImage-|-"+i} className="form-control-file" />
                                        <div className="fix-cart">
                                        <img src={item.image || 'http://via.placeholder.com/500x300'} id={"placeImage-|-"+i} className="fix-img" alt={"placeImage"+i} />
                                        </div>                     
                                      </div>
                                  </div>
                                  <div className="line" />
                              </div>
                              
                            })
                            }
                            <div className="form-group row">   
                        <label style={{cursor: 'pointer'}} className="col-sm-9 form-control-label"
                          onClick={this.handleAddPlace.bind(this)}><FontAwesomeIcon 
                          
                            color= '#48964b' icon={faPlusCircle} 
                            style={{marginBottom:0, marginRight:5}}/>
                           
                       Thêm vị trí cửa hàng (Nếu bạn có nhiều cửa hàng thì có thể thêm nhiều vị trí)</label>
                       
                       </div>
                       </div>

                            
                      

                      
                <div style={{display: typeParam!=="shop_service"?"none":""}}>
                    
                <h3 style={{marginBottom:20}}>Thông tin thành viên</h3>
                    <div className="form-group row">
                        
                        <label className="col-sm-3 form-control-label" >Chính sách thành viên</label>
                        <div className="col-sm-9">
                          <select name="memberTypeSelect" className="form-control form-control-sm col-sm-6" value={membership} onChange={this.handleChangeMemberType}>
                          
                          <option value={0}>Không sử dụng</option>
                          <option value={1}>Chỉ cho đăng ký</option>
                          <option value={2}>Cho đăng ký và có điểm thưởng</option>
                              
                         
                          </select>
                      </div>
                      </div>
                    <h3 style={{marginBottom:20}}>Hỗ trợ khách hàng</h3>
           

                      <div className="form-group row">
                        
                        <label className="col-sm-3 form-control-label" >Thanh toán & Vận chuyển</label>
                        <div className="col-sm-6">
                        <input type="text" placeholder="Miễn phí vận chuyển cho đơn hàng từ 500.000đ" onChange={this.handleChange} name="serviceShip" value={serviceShip}  className="form-control" />
                        </div>
                      </div>

                      <div className="form-group row">
                        
                        <label className="col-sm-3 form-control-label" >Đổi trả & Hoàn tiền</label>
                        <div className="col-sm-6">
                        <input type="text" placeholder="Đảm bảo hoàn tiền 100% giá trị đơn hàng" onChange={this.handleChange} name="serviceRefund" value={serviceRefund}  className="form-control" />
                        </div>
                      </div>

                      <div className="form-group row">
                        
                        <label className="col-sm-3 form-control-label" >Hỗ trợ</label>
                        <div className="col-sm-6">
                        <input type="text" placeholder="Hỗ trợ trực tuyến 24/7" onChange={this.handleChange} name="serviceSupport" value={serviceSupport}  className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <h3 style={{marginBottom:20}}>Hướng dẫn mua hàng</h3>
           

                       <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Hướng dẫn khách mua hàng trên Website</label>
                        <div className="col-sm-9">
                          <ReactQuill
                            name = "aboutShort"
                            modules={modules}
                            formats={formats}
                            value={shopGuide}
                            onChange={this.handleChangeShopGuide} />
                        </div>
                      </div>

                      
                </div>
                      
                <div style={{display: typeParam!=="shop_banner"?"none":""}}>
                
                <h3 style={{marginBottom:20}}>Giao diện website</h3>
                <div className="form-group row">
                <label className="col-sm-3 form-control-label">Giao diện</label>
                        <div className="col-sm-9">
                          <select name="tempateSelect" className="form-control form-control-sm col-sm-6" value={this.state.template?this.state.template.id:""} onChange={this.handleChangeTemplate}>
                          
                          {this.state.templates && this.state.templates.map((item, index) => {
                            return(
                              <option value={item.id} name={item.name}>{item.name}</option>
                              )
                          })}
                         
                          </select>
                        </div>
                      </div>
                    <h3 style={{marginBottom:20}}>Banner giới thiệu</h3>
                    {this.state.introBanners.length>0 && this.state.introBanners.map( ( item, i ) =>{
                              return <div key={  '-i' + i }style={{marginLeft:20}}>
                              <h4 >Banner {i+1}
                              <sup> <FontAwesomeIcon 
                                      onClick={this.removeBanner.bind(this,i)}
                                      color= 'Tomato' icon={faTrashAlt} 
                                      style={{ marginTop:10,cursor: "pointer"}}/>
                                      </sup></h4>
                             
                              {this.state.template?.banner?.top.image?
                      <div className="form-group row">
                        <label htmlFor="fileInput" className="col-sm-3 form-control-label">Nền khung Banner</label>
                        <div className="col-sm-9">
                          <input type="file" onChange={this.handleChangeBannerImage} name={"image-|-"+i} className="form-control-file" />
                          <div className="fix-cart">
                          <img src={item.image.indexOf("blob:")===0?item.image:item.image+"?a="+Math.random() || 'http://via.placeholder.com/1668x629'} id={"image-|-"+i} className="fix-img" alt={"image"+i} />
                          </div>                     
                        </div>
                      </div>:null} 
                     
               
                      {this.state.template?.banner?.top.subImage?<div className="form-group row">
                          <label htmlFor="fileInput" className="col-sm-3 form-control-label">Hình ảnh Banner</label>
                          <div className="col-sm-9">
                            
                            <input type="file" onChange={this.handleChangeBannerImage} name={"subImage-|-"+i} className="form-control-file" />
                            <div className="fix-cart">
      
                            <img src={item.subImage.indexOf("blob:")===0?item.subImage:item.subImage+"?a="+Math.random() || 'http://via.placeholder.com/620x300'} id={"subImage-|-"+i} className="fix-img" alt={"subImage"+i} />
                            </div>                     
                          </div>
                        </div>:null}

                    
                        {this.state.template?.banner?.top.title?<div className="form-group row">
                        <label className="col-sm-3 form-control-label">Tựa đề Banner</label>
                        <div className="col-sm-6">
                          <input placeholder="Giảm giá <br /> <strong>50%</strong>" type="text" onChange={this.handleChangeBanner} name={"title-|-"+i} value={item.title} className="form-control" />
                        </div>
                        
                        
                      </div>:null}
                      {this.state.template?.banner?.top.title?<div className="form-group row">
                        <label className="col-sm-3 form-control-label">Màu Chữ Tựa đề Banner</label>
                        <div className="col-sm-6">
                        <TwitterPicker
                         className="col-sm-9 form-control-label"
                         name={"titleColor-|-"+i}
                         color={ item.titleColor }
                          onChangeComplete={ this.handleChangeColorTextBanner( "titleColor-|-"+i) }
                        />  
                         </div>
                        
                        
                      </div>:null}

                     
                      {this.state.template?.banner?.top.aSubtitle?<div className="form-group row">
                        
                        <label className="col-sm-3 form-control-label" >Tựa đề con Banner</label>
                        <div className="col-sm-6">
                        <input type="text" placeholder="Siêu giảm giá mùa hè" onChange={this.handleChangeBanner} name={"aSubtitle-|-"+i}  value={item.aSubtitle}  className="form-control" />
                        </div>
                      </div>:null}
                      {this.state.template?.banner?.top.aSubtitle?<div className="form-group row">
                        
                        <label className="col-sm-3 form-control-label" >Màu Chữ Tựa đề con Banner</label>
                        <div className="col-sm-6">
                        <TwitterPicker
                         className="col-sm-9 form-control-label"
                         name={"aSubtitleColor-|-"+i}
                         color={ item.aSubtitleColor }
                          onChangeComplete={ this.handleChangeColorTextBanner("aSubtitleColor-|-"+i) }
                        /> 
                        </div>
                      </div>:null}
                      {this.state.template?.banner?.top.area?
                      <div>
                     
                      <div className="form-group row">
                      <label className="col-sm-3 form-control-label">Vị trí hiển thị</label>
                        <div className="col-sm-9">
                          <select name={"area-|-"+i}  value={item.area} className="form-control form-control-sm col-sm-4"  onChange={this.handleChangeBanner}>
                          
                          <option value={"top"} name={"top"}>Banner trên (Xoay vòng)</option>
                          <option value={"newsletter"} name={"newsletter"}>Banner Poup (Newsletter)</option>
                          <option value={"mid"} name={"mid"}>Banner giữa</option>
                         
                          </select>
                        </div>
                      </div>
                      </div>:null}
                      {this.state.template?.banner?.top.link?<div className="form-group row">
                        
                        <label className="col-sm-3 form-control-label" >Đường dẫn</label>
                        <div className="col-sm-6">
                        <input type="text" placeholder="https://abc.com/danh_muc" onChange={this.handleChangeBanner} name={"link-|-"+i}  value={item.link}  className="form-control" />
                        </div>
                      </div>:null}
                      <div className="line" />
                    </div>})}
                    <div className="form-group row" style={{marginLeft:10}} >   
                        <label style={{cursor: 'pointer'}} className="col-sm-9 form-control-label"
                          onClick={this.handleAddBanner.bind(this)}><FontAwesomeIcon 
                          
                            color= '#48964b' icon={faPlusCircle} 
                            style={{marginBottom:0, marginRight:5}}/>
                           
                       Thêm Banner</label>
                       
                       </div>
                      {this.state.template?.banner?.bottom?
                      <div>
                      
                      <h3 style={{marginBottom:20}}>Banner dưới</h3>
                    
                      <div className="form-group row">
                        <label htmlFor="fileInput" className="col-sm-3 form-control-label">Hình ảnh Banner dưới</label>
                        <div className="col-sm-9">
                          <input type="file" onChange={this.handleChangeImage} name="bannerBotomLink" className="form-control-file" />
                          <div className="fix-cart">
                          <img src={bannerBotomLink || 'http://via.placeholder.com/400x300'} id="bannerBotomLink" className="fix-img" alt="bannerBotomLink" />
                          </div>                     
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Tựa đề Banner dưới</label>
                        <div className="col-sm-6">
                          <input placeholder="Xu hướng mới" type="text" onChange={this.handleChange} name="bottomBannerTitle" value={bottomBannerTitle} className="form-control" />
                        </div>
                        
                      </div>

                     
                      <div className="form-group row">
                        
                        <label className="col-sm-3 form-control-label" >Tựa đề con Banner dưới</label>
                        <div className="col-sm-6">
                        <input type="text" placeholder="Thiên Đường Gọi Tên" onChange={this.handleChange} name="bottomBannerASubTitle" value={bottomBannerASubTitle}  className="form-control" />
                        </div>
                      </div>
                      <div className="form-group row">
                        
                        <label className="col-sm-3 form-control-label" >Đường dẫn</label>
                        <div className="col-sm-6">
                        <input type="text" placeholder="https://abc.com/abc" onChange={this.handleChange} name="bannerBotomUrl" value={bannerBotomUrl}  className="form-control" />
                        </div>
                      </div>
                      
                      
                      <div className="form-group row">
                        
                        <label className="col-sm-3 form-control-label" >Nội dung Banner dưới</label>
                        <div className="col-sm-6">
                        <ReactQuill
                          name = "aboutLong"
                            modules={modules}
                            formats={formats}
                            value={bottomBannerDescription}
                            onChange={this.handleChangeBottomBannerDescription} />
                        </div>
                      </div>
                      
                      </div>
                      :null}
                      

                      
                </div>
                <div style={{display: typeParam!=="shop_about"?"none":""}}>
              
                    <h3 style={{marginBottom:20}}>Giới thiệu về Cửa hàng</h3>
                      
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Giới thiệu ngắn gọn về Cửa hàng</label>
                        <div className="col-sm-9">
                          <ReactQuill
                            name = "aboutShort"
                            modules={modules}
                            formats={formats}
                            value={aboutShort}
                            onChange={this.handleChangeAboutShort} />
                        </div>
                      </div>
                
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Giới thiệu đầy đủ về Cửa hàng</label>
                        <div className="col-sm-9">
                          <ReactQuill
                          name = "aboutLong"
                            modules={modules}
                            formats={formats}
                            value={aboutLong}
                            onChange={this.handleChangeAboutLong} />
                        </div>
                      </div>
                  
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Tầm nhìn, đặc trưng của Cửa hàng</label>
                        <div className="col-sm-9">
                          <ReactQuill
                          name = "feature"
                            modules={modules}
                            formats={formats}
                            value={feature}
                            onChange={this.handleChangeFeature} />
                        </div>
                      </div>
                </div>

                <div style={{display: typeParam!=="seo_top_google"?"none":""}}>
              
                    <h3 style={{marginBottom:20}}>SEO lên top Google</h3>
                      
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Từ khóa (liệt kê các từ khóa liên quan đến sản phẩm - dịch vụ của bạn cách nhau bởi dấu ",")</label>
                        
                        <div className="col-sm-6">
                          <textarea
                           style={{width:"100%"}}
                            name = "keyworld"
                            value={this.state.keyworld}
                            onChange={this.handleChangeKeyworld} />
                        </div>
                      </div>
                </div>
                      
                     


                      <div className="line" />
                      <div className="form-group row">
                        <div className="col-sm-4 offset-sm-3">
                          <button type="reset" className="btn btn-secondary" style={{ marginRight: 2 }}>Cancel</button>
                          <button type="submit" className="btn btn-primary">Lưu</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/}
        <MyFooter></MyFooter>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add_user: (token, newUser) => {
      dispatch(actAddUserRequest(token, newUser))
    },
    get_user: (token, id) => {
      dispatch(actGetUserRequest(token, id))
    },
    edit_user: (token, id, data) => {
      dispatch(actEditUserRequest(token, id, data))
    }
  }
}
export default connect(null, mapDispatchToProps)(ActionUserShop)
