import React, {PureComponent} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import colors from "../../constants/colors";

const styles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${colors.light}`,
  
    },
    image: {
        width: '10%',
        padding: 10,
        '& img': {
            width: '100%'
        }
    },
    product: {
        width: '37%'
    },
    name: {
        fontSize: 12
    },
    qty: {
        width: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    subtotal: {
        width: '30%',
        textAlign: 'right',
        paddingRight: 0
    },
    delete: {
        width: '8%',
        '& .MuiSvgIcon-root': {
            fontSize: 16,
            color: colors.grey
        }
    }
}

class CartItemGeneral extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            price:0,
            name:""
        }
    }
    handelChangePrice = (e) => {
        this.props.onChangePrice(this.props._id,parseInt(e.target.value))
        this.setState({ price: e.target.value });
      }
    handelChangeName = (e) => {
        this.props.onChangeName(this.props._id,e.target.value)
    this.setState({ name: e.target.value });
    }
    render() {
        const { classes, _id, qty, changeQty, deleteItem } = this.props
        const { price, name } = this.state
       
        return (
            <Box className={classes.root}>
                <Box className={classes.delete}>
                    <IconButton onClick={() => deleteItem(_id)}>
                        <DeleteOutlineIcon/>
                    </IconButton>
                </Box>
              
                <Box className={classes.product}>
                    <Input placeholder="Nhập tên sản phẩm" variant="caption" onChange={(e) => {this.handelChangeName(e)}} style={{fontSize:12,width:"100%", color:"hotpink"}} value={ name }/>
                    <Input variant="body2" onChange={this.handelChangePrice} style={{fontSize:12,width:"100%",color:"hotpink"}} value={price}/>
                </Box>
                <Box className={classes.qty}>
                    <IconButton color="primary" onClick={() => changeQty(_id, qty-1)}>
                        <RemoveCircleOutlineIcon/>
                    </IconButton>
                    <Typography variant="caption">{ qty }</Typography>
                    <IconButton color="primary" onClick={() => changeQty(_id, qty+1)}>
                        <AddCircleOutlineIcon/>
                    </IconButton>
                </Box>
                <Box style={{width: "30%",
    textAlign: "right"}}>
                    <Typography variant="subtitle2" style={{marginRight:-10}}>{ (qty* price).toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } ) +"đ" }</Typography>
                </Box>
            </Box>
        );
    }
}

export default withStyles(styles)(CartItemGeneral)