import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createStore, applyMiddleware } from 'redux';
import appReducers from './redux/reducers/index';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import 'nprogress/nprogress.css'
import { ToastContainer } from 'react-toastify';
import MessengerCustomerChat from 'react-messenger-customer-chat';
const store = createStore(appReducers, composeWithDevTools(
  applyMiddleware(thunk)
));

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ToastContainer
      position="top-right"
      autoClose={1700}
      pauseOnHover={false}
      pauseOnVisibilityChange={false}
    />
    <MessengerCustomerChat
                pageId={"108386818207536"}
                shouldShowDialog={false}
                
                appId="389527678766474"
                /* themeColor={#eea287} */
               
            />
    
  </Provider>,
  document.getElementById('root'));
