import React, { Component } from 'react'
import Login from '../components/Login/Login'
import ForgotPassword from '../components/Login/ForgotPassword';
import ChangePassword from '../components/Login/ChangePassword';

export default class LoginPage extends Component {
  constructor(props) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const email = params.get('email')
    const forgotLink = params.get('forgotLink')
    const token = params.get('token')
    super(props);
    this.state = {
      forgot: forgotLink&&forgotLink?true:false,
      email,
      token
    }
  }
 
  forgotClick = () => {
    this.setState({forgot: !this.state.forgot})
  };
  render() {
    return (

      this.state.token&&this.state.token.length>0?<ChangePassword email={this.state.email} token={this.state.token}/>:this.state.forgot?<ForgotPassword forgotClick={this.forgotClick}/>:<Login email={this.state.email} forgotClick={this.forgotClick}/>
    )
  }
}
