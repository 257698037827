import { storage } from '../firebase';
import callApi from './apiCaller';
import Resizer from "react-image-file-resizer";
const provider = "s3"

const resizeFile = (file,q) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "JPEG",
      q,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

 async function uploadImage(_image) {
  const image = await resizeFile(_image);
  return new Promise((resolve, reject) => {
    const uploadTask = storage.ref(`images/${image.name}`).put(image);
    uploadTask.on('state_changed',
      (snapshot) => {
        // progrss function ....
      },
      (error) => {
        // error function ....
        reject(error)
      },
      () => {
        // complete function ....
        storage.ref('images').child(image.name).getDownloadURL().then(url => {
          resolve(url);
        })
      });
  })
}


export async function sendFile(typeLink,file,onSuccess,token) {
 
  const presignedData = await callApi('get-link-upload/'+typeLink+"/"+file.type, 'GET', null, token);
  
  if(presignedData.data.presignLink != null){
    var xhr = new XMLHttpRequest();
    xhr.open('PUT', presignedData.data.presignLink, true);
    xhr.setRequestHeader('X-Amz-ACL', 'public-read');
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.onload = () => {
      if (xhr.status === 200) {
        onSuccess(presignedData.data.urlStr)
      }
    };
    xhr.onerror = () => {
      onSuccess("")
  
    };
    xhr.send(file); // `file` is a File object here 
  }
};

export async function uploadS3(typeLink,file,token) {
  
  typeLink = "shop_"+typeLink
  if (provider === "s3"){
    var fileType = "jpeg"
    try {
      if (typeLink.indexOf("shop_productImage")>-1
      ||typeLink.indexOf("productGalery")>-1){
        fileType = "jpeg"
      }else{
        fileType = file.type
      }
      
    } catch (error) {
      console.log("error "+error)
      fileType = "jpeg"
    }
    
    const presignedData = await callApi('get-link-upload/'+typeLink+"/"+fileType.replace('image/',''), 'GET', null, token);
    
    let image ;
   
    if (typeLink.indexOf("shop_productImage")>-1
      ||typeLink.indexOf("productGalery")>-1){
        image = await resizeFile(file,80)
        
       
      }else{
        image = file
      }
       
  
      if(presignedData.data.presignLink != null){
      let response = await new Promise(resolve => {
      var xhr = new XMLHttpRequest();
      xhr.open('PUT', presignedData.data.presignLink, true);
      xhr.setRequestHeader('X-Amz-ACL', 'public-read');
      xhr.setRequestHeader('Content-Type', image.type);
      xhr.onload = () => {
        if (xhr.status === 200) {
          
          resolve(presignedData.data.urlStr); 
        }else{
          resolve(""); 
        }
      };
      xhr.onerror = () => {
        resolve("");
    
      };
      xhr.send(image); // `file` is a File object here 
    
    }) 
    return response
    
    }
    }else{
      var res = await uploadImage(file)
      console.log(res)
      return res
    }
  
};

