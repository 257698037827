import React, { Component } from 'react'
import MyFooter from '../../MyFooter/MyFooter'
import { actFetchDashboardRequest } from '../../../redux/actions/dashboard'
import { connect } from 'react-redux'
import { Line, HorizontalBar, Pie } from 'react-chartjs-2';
import Iframe from 'react-iframe'
import './style.css'
import callApi from '../../../utils/apiCaller';
import { actTokenRequest } from "../../../redux/actions/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCheck,faShippingFast,faHandHoldingUsd,faWindowClose } from '@fortawesome/free-solid-svg-icons'
let token;
class DashBoard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      January: 0,
      February: 0,
      March: 0,
      April: 0,
      May: 0,
      June: 0,
      July: 0,
      August: 0,
      September: 0,
      October: 0,
      November: 0,
      December: 0,
      January1: 0,
      February1: 0,
      March1: 0,
      April1: 0,
      May1: 0,
      June1: 0,
      July1: 0,
      August1: 0,
      September1: 0,
      October1: 0,
      November1: 0,
      December1: 0,
      labelsPie: [],
      dataShowPie: []
      
    }
  }

  async componentDidMount() {
    token = localStorage.getItem('_auth');
    if (token) {
      this.props.fetch_dashboard(token);
      // const category =  callApi('reports/products', 'GET', null, token);
      // const [resCategory ] = await Promise.all([category]);
      // if (resCategory) {
      //   this.setState({
      //     labelsPie: category.data.map(e => e.nameCategory),
      //     dataShowPie: category.data.map(e => e.count)
      //   })
      // }
    } else {
      this.props.add_token_redux(null);
    }
  }

  render() {
    const { January, February, March, April, May, June, July, August, September, October, November, December,
      January1, February1, March1, April1, May1, June1, July1, August1, September1, October1, November1, December1, labelsPie, dataShowPie
    } = this.state
    const dataLine = {  
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      datasets: [
        {
          label: 'Count contact',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [January1, February1, March1, April1, May1, June1, July1, August1, September1, October1, November1, December1]
        }
      ]
    };
    const dataHozi = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      datasets: [
        {
          label: 'ORDER',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [January, February, March, April, May, June, July, August, September, October, November, December]
        }
      ]
    };
    const dataPie = {
      labels: labelsPie,
      datasets: [{
        data: dataShowPie,
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#b42b2b',
          '#6aea5a',
        ],
        hoverBackgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#b42b2b',
          '#6aea5a',
        ]
      }]
    };
    const { dashboard,user } = this.props
    
    return (
      <div className="content-inner">
        {/* Page Header*/}
        <header className="page-header">
          <div className="container-fluid">
            <h2 className="no-margin-bottom">Báo Cáo Tình Trang Đơn Hàng</h2>
          </div>
        </header>
        {/* Dashboard Counts Section*/}
        <section className="dashboard-counts no-padding-bottom">
          <div className="container-fluid">
            <div className="row bg-white has-shadow">
              {/* Item */}
              <div className="col-xl-3 col-sm-6">
                <div className="item d-flex align-items-center">
                  <div className="icon bg-violet"><i className="icon-padnote" /></div>
                  <div className="title"><span>Đơn Hàng<br />Mới</span>
                    <div className="progress">
                      <div role="progressbar" style={{ width: '100%', height: '4px' }} className="progress-bar bg-violet fix-processbar" />
                    </div>
                  </div>
                  <div className="number"><strong>{dashboard.unconfirm?.total}</strong></div>
                </div>
              </div>
              {/* Item */}
              <div className="col-xl-3 col-sm-6">
                <div className="item d-flex align-items-center">
                  <div className="icon bg-red"><FontAwesomeIcon icon={faUserCheck} /> </div>
                  <div className="title"><span>Đã Xác Nhận<br />Với Khách</span>
                    <div className="progress">
                      <div role="progressbar" style={{ width: '100%', height: '4px' }} className="progress-bar bg-red fix-processbar" />
                    </div>
                  </div>
                  <div className="number"><strong>{dashboard.confirm?.total}</strong></div>
                </div>
              </div>
              {/* Item */}
              {/* <div className="col-xl-3 col-sm-6">
                <div className="item d-flex align-items-center">
                  <div className="icon bg-green"><FontAwesomeIcon icon={faBoxOpen} /></div>
                  <div className="title"><span>Đợi Giao<br />Shipper</span>
                    <div className="progress">
                      <div role="progressbar" style={{ width: '100%', height: '4px' }} className="progress-bar bg-green fix-processbar" />
                    </div>
                  </div>
                  <div className="number"><strong>{dashboard.waitship?.total}</strong></div>
                </div>
              </div> */}
              {/* Item */}
              <div className="col-xl-3 col-sm-6">
                <div className="item d-flex align-items-center">
                  <div className="icon bg-orange"><FontAwesomeIcon icon={faShippingFast} /></div>
                  <div className="title"><span>Shipper Đang <br />Vận Chuyển</span>
                    <div className="progress">
                      <div role="progressbar" style={{ width: '100%', height: '4px' }} className="progress-bar bg-orange fix-processbar" />
                    </div>
                  </div>
                  <div className="number"><strong>{dashboard.shipping?.total}</strong></div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="item d-flex align-items-center">
                  <div className="icon bg-orange"><FontAwesomeIcon icon={faHandHoldingUsd} /></div>
                  <div className="title"><span>Hoàn Thành</span>
                    <div className="progress">
                      <div role="progressbar" style={{ width: '100%', height: '4px' }} className="progress-bar bg-orange fix-processbar" />
                    </div>
                  </div>
                  <div className="number"><strong>{dashboard.complete?.total}</strong></div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="item d-flex align-items-center">
                  <div className="icon bg-orange"><FontAwesomeIcon icon={faWindowClose} /></div>
                  <div className="title"><span>Đã Hủy</span>
                    <div className="progress">
                      <div role="progressbar" style={{ width: '100%', height: '4px' }} className="progress-bar bg-orange fix-processbar" />
                    </div>
                  </div>
                  <div className="number"><strong>{dashboard.canceled?.total}</strong></div>
                </div>
              </div>
            </div>
            <h3 style={{paddingTop: 40,paddingBottom:20}}>Phân Tích Dữ Liệu Website</h3>
            {user && user.ShopData?.dashBoardId && user.ShopData?.dashBoardId.length?
             <div 
            style={{

              
            }}>
            <Iframe url={`https://app.databox.com/datawall/${user.ShopData?.dashBoardId}?i`}
              width="95%"
              height="80%"
              frameborder="0"
              className="myClassname"
              display="initial"
              position="absolute"
              allowFullScreen="true"
              webkitAllowFullScreen="true"
              mozAllowFullScreen="true"
              
            
             
              />
            
            </div>
            :null}
           
            
           {/* <br />
            <h3>Report Total Income</h3>
            <HorizontalBar 
            width={100}
            height={30} data={dataHozi} />
             <br />
             <br />
             <h3>Report Contact</h3>
             <Line  width={100}
            height={15}
              data={dataLine} />
            {/* <h3 style={{paddingTop: 20}}>Thống kê Sản Phẩm Theo Danh Mục</h3>
            <Pie 
              width={100}
              height={25} data={dataPie} />
            <br /> */}
            {/* <br />
            <h3>Report Total Income</h3>
            <HorizontalBar 
            width={100}
            height={30} data={dataHozi} />
             <br />
             <br />
             <h3>Report Contact</h3>
             <Line  width={100}
            height={15}
              data={dataLine} /> */}
          </div>
        </section>
        {/* {user && user.ShopData?.dashBoardId && user.ShopData?.dashBoardId.length!==0? <MyFooter></MyFooter>:null} */}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboard,
    user:state.infoMe
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetch_dashboard: (token) => {
      dispatch(actFetchDashboardRequest(token))
    },
    add_token_redux: token => {
      dispatch(actTokenRequest(token));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard)
