import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import Moment from 'react-moment';
const colors = {
    darkPurple: '#303456',
    red: '#d63a47',
    fanta: '#C24C89',
    pink: '#EA5179',
    lightpink: '#e6a3c5',
    black: '#000000',
    dark: '#4d4d4d',
    grey: '#9c9c9c',
    lightgrey: '#ECF0EF',
    light: '#F7F7F7',
    white: '#FFFFFF',
    peach: '#FF9B6A',
    blue: '#5a9cf5',
    skyblue: '#6adaff',
    green: '#44C7A7',
    leavegreen: '#99CC68'
}
const styles = {
    box: {
        textAlign: 'center',
        padding: 0,
       
    },
    title: {
        fontWeight: 500,
        fontSize: 30,
        color: colors.black
    },
    table: {
        marginTop: 15,
        marginBottom: 15,
        '& .MuiTableHead-root': {
            borderTop: `2px solid ${colors.lightgrey}`,
            borderBottom: `2px solid ${colors.lightgrey}`,
            '& th': {
                padding: 10
            }
        },
        '& .MuiTableBody-root': {
            borderBottom: `2px solid ${colors.lightgrey}`,
        },
        '& td': {
            padding: 7
        }
    },
    calculateTable: {
        marginBottom: 20,
        '& th': {
            padding: 5,
            fontWeight: 500,
            borderBottom: `1px solid ${colors.lightgrey}`
        }
    },
    grandTotal: {
        color: colors.black,
        fontSize: 18,
        fontWeight: 600
    }
}

class ReceiptPrint extends React.Component {
    findIndex = function ( array, cb ) {
        if ( array ) {
            for ( let i = 0; i < array.length; i++ ) {
                if ( true === cb( array[ i ] ) )
                    return i;
            }
        }
        return 0;
      }
  
    render() {
        const { staff,customer_name,customer_address, customer_phone,customer_note,classes, createdat, id, products, subtotal,shippingTotal, discount, grandtotal,tax } = this.props

        return (
            <Box className={classes.box}>
                <Typography className={classes.title}>HÓA ĐƠN MUA HÀNG</Typography>
                <Typography variant="caption"> Tạo lúc: <Moment format="HH:mm DD/MM/yyyy">
                                    {createdat}
                                  </Moment></Typography><br/>
                <Typography variant="caption"> Mã đơn: { id }</Typography>
                <br/>
                <Typography variant="caption"> -----------------------</Typography>
                <br/>
             
           
                
                <Typography style={{fontSize: 12, marginLeft:15}} align="left"> Khách Hàng: <span style={{fontWeight:'bold'}}>{ customer_name }</span></Typography>
               
                <Typography  style={{fontSize: 12, marginLeft:15}} align="left"> Số Điện Thoại: <span style={{fontWeight:'bold'}}>{ customer_phone }</span></Typography>
                
                <Typography  style={{fontSize: 12, marginLeft:15}} align="left"> Địa Chỉ: <span style={{fontWeight:'bold'}}>{ customer_address }</span></Typography>
               {customer_note&&customer_note.length>0?<Typography  style={{fontSize: 12, marginLeft:15}} align="left"> Lưu ý: <span style={{fontWeight:'bold'}}>{ customer_note }</span></Typography>:null}
                

                <Table >
                    <TableHead>
                        <TableRow >
                            <TableCell>Sản Phẩm</TableCell>
                            <TableCell align="center">S.Lượng</TableCell>
                            <TableCell align="center">Đơn Giá</TableCell>
                            <TableCell align="right">T.Tiền</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.map((item, i) => {
                            const _index = this.findIndex( item.product.fPrices, _item => _item.unit === item.product.unit );
                           
                            const _price = item.product.fPrices[_index].price
                            return (
                                <TableRow key={i}>
                                    <TableCell>
                                        <Typography variant="subtitle2">{ item.nameProduct } ({ item.unit })</Typography>
                                        <Typography variant="caption">SKU: {item.sku}</Typography>
                                    </TableCell>
                                    <TableCell align="center">{ item.quantity }</TableCell>
                                    <TableCell align="center">{_price*item.quantity-item.price>0?(item.price/item.quantity).toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } )+"đ":_price.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } )}{_price*item.quantity-item.price>0?<sup style={{textDecoration: "line-through"}}>{_price.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } )}đ</sup>:<span></span>}</TableCell>
                                    <TableCell align="right">{ item.price.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } )}đ </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                <Table className={classes.calculateTable}>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" colSpan={3} align="right">Tổng Tiền Hàng</TableCell>
                            <TableCell component="th" align="right">{ subtotal }</TableCell>
                        </TableRow>
                        { shippingTotal !== undefined &&(<TableRow>
                            <TableCell component="th" colSpan={3} align="right">Phí Ship</TableCell>
                            <TableCell component="th" align="right">{ shippingTotal && `${ shippingTotal }`}</TableCell>
                        </TableRow>)}
                        
                        { discount !== "0đ" && (<TableRow>
                            <TableCell component="th" colSpan={3} align="right">Giảm Giá</TableCell>
                            <TableCell component="th" align="right">{ discount && `${ discount }`}</TableCell>
                        </TableRow>)}
                        { tax !== "0đ" && (<TableRow>
                            <TableCell component="th" colSpan={3} align="right">Thuế(VAT)</TableCell>
                            <TableCell component="th" align="right">{ tax && `${ tax }`}</TableCell>
                        </TableRow>)}
                        <TableRow>
                            <TableCell component="th" colSpan={3} align="right">
                                <Typography className={classes.grandTotal}>Tổng Tiền Thanh Toán</Typography>
                            </TableCell>
                            <TableCell component="th" align="right">
                                <Typography className={classes.grandTotal}>{ grandtotal }</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    
                </Table>
                    <Typography style={{fontSize: 12, marginLeft:15}} align="right"> Nhân Viên: { staff }</Typography>
                    <Typography style={{fontSize: 12, marginLeft:15}} align="right"> In Lúc: 
                        <Moment format="HH:mm DD/MM/yyyy">
                                            {new Date().toString()}
                                        </Moment>
                    </Typography>
                    <Typography style={{fontSize: 12, marginLeft:15, height:20}} align="right"> </Typography>
                   
                
            </Box>
        );
    }
}

export default withStyles(styles)(ReceiptPrint)