import React, { Component } from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { actFetchProductsRequest, actDeleteProductRequest, actFindProductsRequest } from '../../../redux/actions/product';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import MyFooter from '../../MyFooter/MyFooter'
import {exportExcel} from '../../../utils/exportExcel'
import { currencyFormatVND } from '../../../utils/util'
import Paginator from 'react-js-paginator';
import callApi from '../../../utils/apiCaller';
const MySwal = withReactContent(Swal)

let token;


class Product extends Component {

  constructor(props) {
    
    super(props);
    this.state = {
      dataCategories:[{
        id:"All",
        nameCategory:"Tất cả",
      }],
      categoryId:"All",
      total: 0,
      currentPage: 1,
      searchText: '',
      changPage:true
    }
  }

async loadCategory (){
  token = localStorage.getItem('_auth');
  const resCategories = await callApi('categories?limit=100', 'GET', null, token);

  let cloneArray = [...resCategories.data.results];
  cloneArray.unshift({
    id:"All",
    categoryName:"Tất cả",
})
  this.setState({
      dataCategories: cloneArray,
    })
}
handleFilter = (event) => {
  let value = event.target.value
  this.setState({
    categoryId:value,
    searchText:"",
    currentPage: 1,
  })
  console.log("value "+value)
  event.preventDefault();
  this.fetch_reload_data(value);
  this.resetPage()
}
 componentDidMount() {
   
    this.loadCategory()
    this.fetch_reload_data();
  }
 
  fetch_reload_data(categoryType){
    token = localStorage.getItem('_auth');
    this.props.fetch_products(token,0,categoryType?categoryType:null).then(res => {
      this.setState({
        total: res.total
      });
    }).catch(err => {
      console.log(err);  
    })
  }
  resetPage =()=> {
    this.setState({
      changPage:false,
    })
    const that = this
    setTimeout(function(){ 
      that.setState({
        changPage:true,
      })
     }, 200);
  }
  handleSubmit = (event) => {
    event.preventDefault();
    const { searchText } = this.state;
    this.props.find_products(token, searchText,0,this.state.categoryId).then(res => {
      this.setState({
        total: res.total,
        currentPage: 1,
        
      })
      this.resetPage()
     
      
    })
  }
  pageChange(content){
    
    const { searchText } = this.state;
    const limit = 10;
    const offset = limit * (content - 1);
    if (searchText.length === 0){
      this.props.fetch_products(token, offset,this.state.categoryId?this.state.categoryId:null);
      this.setState({
        currentPage: content
      })
    }else{
      this.props.find_products(token, searchText,offset,this.categoryId).then(res => {
        this.setState({
          total: res.total,
          currentPage: content
        })
      })
    }
    

    
    

    window.scrollTo(0, 0);
  }

  handleRemove = (id) => {
    MySwal.fire({
      title: 'Xác nhận',
      text: "Bạn muốn xóa sản phẩm này?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(async (result) => {
      if (result.value) {
        await this.props.delete_product(id, token);
        Swal.fire(
          'Đã xóa!',
          'Sản phẩm đã được xóa.',
          'success'
        )
      }
    })
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

 

  downloadExcel = () => {
    const key = 'products'
    exportExcel(key)
  }

  render() {
    let { products } = this.props;
    const { searchText, total } = this.state;
    return (
      <div className="content-inner">
        {/* Page Header*/}
        <header className="page-header">
          <div className="container-fluid">
            <h2 className="no-margin-bottom">Sản phẩm</h2>
          </div>
        </header>
        {/* Breadcrumb*/}
        <div className="breadcrumb-holder container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Trang chủ</Link></li>
            <li className="breadcrumb-item active">Sản phẩm</li>
          </ul>
        </div>
        <section className="tables pt-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h3 className="h4">Danh sách sản phẩm </h3> <h3 style={{color:"green", marginLeft:10}}> ({total} sản phẩm)</h3>
                    
                  </div>
                  <form onSubmit={(event) => this.handleSubmit(event)}
                    className="form-inline md-form form-sm mt-0" style={{ justifyContent: 'flex-end', paddingTop: 5, paddingRight: 20 }}>
                    <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Danh mục</label>
                        <div className="col-sm-9">
                          <select name="filter" className="form-control form-control-sm ml-3 w-75" value={this.state.categoryId} onChange={this.handleFilter}>
                          
                          {this.state.dataCategories.map((item, index) => {
                            return(
                              <option value={item.id}>{item.nameCategory}</option>
                              )
                          })}
                         
                          </select>
                        </div>
                      </div>
                    <div>
                      <button style={{border: 0, background: 'white',width:35}}> <i className="fa fa-search" aria-hidden="true"></i></button>                  
                      <input
                        name="searchText"
                        onChange={this.handleChange}
                        value={searchText}
                        className="form-control form-control-sm ml-3 w-75" type="text" placeholder="Search"
                        aria-label="Search" />
                    </div>
                    
                    <Link to='/products/add' className="btn btn-primary" > Tạo mới</Link>
                  </form>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Số thứ tự</th>
                            <th>Tên sản phẩm</th>
                            <th>Mã sản phẩm</th>
                            <th>Hình ảnh</th>
                            <th>Giá</th>
                            <th>Danh mục</th>
                            <th>Còn lại</th>
                            {/* <th>Properties</th> */}
                            
                            <th style={{ textAlign: "center" }}>Trạng thái</th>
                            <th style={{ textAlign: "center" }}>Hành động</th>
                          </tr>
                        </thead>
                        <tbody>
                          {products && products.length ? products.map((item, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{(this.state.currentPage -1)*10 +( index + 1)}</th>
                                <td>{item.nameProduct}</td>
                                <td>{item.sku}</td>
                                <td style={{ textAlign: "center" }}>
                                  <div className="fix-cart3">
                                  <img src={item && item.image ? item.image : null} className="fix-img" alt="not found" />
                                  </div>
                                </td>
                                <td>{currencyFormatVND(item.fPrices[0].price)} đ/{item.fPrices[0].unit}</td>
                                <td>{item.categories.nameCategory}</td>
                                <td>{item.numberAvailable}</td>
                                {/* <td>{item.properties}</td> */}
                                
                                <td style={{ textAlign: "center" }}>{item.isActive ?
                                  <div className="i-checks">
                                    <input type="checkbox" checked={true} className="checkbox-template" />
                                  </div>
                                  :
                                  <div className="i-checks">
                                    <input type="checkbox" checked={false} className="checkbox-template" />
                                  </div>}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <div>
                                    <span title='Edit' className="fix-action"><Link to={`/products/edit/${item.id}`}> <i className="fa fa-edit"></i></Link></span>
                                    <span title='Delete' onClick={() => this.handleRemove(item.id)} className="fix-action"><Link to="#"> <i className="fa fa-trash" style={{ color: '#ff00008f' }}></i></Link></span>
                                  </div>
                                </td>
                              </tr>
                            )
                          }) : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <nav aria-label="Page navigation example" style={{ float: "right" }}>
                  <ul className="pagination">
                    {this.state.changPage?
                      <Paginator
                        currentPage = {5}
                        pageSize={10}
                        totalElements={total}
                        onPageChangeCallback={(e) => {this.pageChange(e)}}
                      />:
                      null}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/}
        <MyFooter></MyFooter>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetch_products: (token, offset,categoryId) => {
       return dispatch(actFetchProductsRequest(token, offset,categoryId))
    },
    delete_product: (id, token) => {
      dispatch(actDeleteProductRequest(id, token))
    },
    find_products: (token, searchText,offset,categoryId) => {
      return dispatch(actFindProductsRequest(token, searchText,offset,categoryId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Product)