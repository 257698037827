import React, { Component } from 'react'
import MyFooter from '../../MyFooter/MyFooter'
import { actAddPostRequest, actGetPostRequest, actEditPostRequest } from '../../../redux/actions/post';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import callApi from '../../../utils/apiCaller';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
let token;
let id;
const override = css`
    display: block;
    margin: 0 auto;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
`;
class ActionPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: true,
      content: '',
      category: {
        "id":"huong_dan",
        "name":"Hướng dẫn",
      },
      image: '',
      redirectToPost: false,
  
      loading: false
    };
    id = this.props.id
  }

  async componentDidMount() {
    token = localStorage.getItem('_auth');
    
    if (id) {
      const res = await callApi(`posts/${id}`, 'GET', null, token);
      this.setState({
        isActive: res.data.isActive,
        title: res.data.title,
        content: res.data.content,
        category: res.data.category,
        
      })
    }
  }


  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  callSubmit = async () => {
    const { isActive, title, content,category } = this.state;
    const newTitle = title === '' ? null : title;
    const newContent = content === '' ? null : content;
    const newCategory= (category === {}) ? {} : category
    if (!id) {
      const newPost = {
        isActive,
        title: newTitle,
        content: newContent,
        category: newCategory
      }
      this.props.add_Post(token, newPost);
      
      this.setState({
        redirectToPost: true
      })
    } else {
      const editPost = {
        isActive,
        title: newTitle,
        content: newContent,
        category: newCategory
      }
      await this.props.edit_Post(token, id, editPost);
      this.setState({
        redirectToPost: true
      })
    }
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      loading: true
    })
    await this.callSubmit()
    
  }
  handleChangeEditor = (value) => {
    this.setState({ content: value })
  }
  handleCategory = (event) => {
    let mCatecory = {
      id:event.target.value,
      name:event.target.name
    }
    this.setState({
      category:mCatecory
    })
    event.preventDefault();
  }
  modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  };

  formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];
  render() {
    const { isActive, title, content, redirectToPost, loading,category } = this.state;
    if (redirectToPost) {
      return <Redirect to='/posts'></Redirect>
    }
    return (
      <div className="content-inner">
        {/* Page Header*/}
        <div className='sweet-loading'>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={30}
            color={'#796aeebd'}
            loading={loading}
          />
        </div>
        <header className="page-header">
          <div className="container-fluid">
          {!id?<h2 className="no-margin-bottom">Thêm bài viết</h2>:<h2 className="no-margin-bottom">Cập nhật thương hiệu</h2>}
          
          </div>
        </header>
        {/* Breadcrumb*/}
        <div className="breadcrumb-holder container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Trang chủ</a></li>
            <li className="breadcrumb-item active">Bài viết</li>
          </ul>
        </div>
        {/* Forms Section*/}
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              {/* Form Elements */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h3 className="h4">Thông tin Bài viết</h3>
                  </div>
                  <div className="card-body">
                    <form className="form-horizontal" onSubmit={(event) => this.handleSubmit(event)} >
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Tiêu đề</label>
                        <div className="col-sm-9">
                          <input name="title" onChange={this.handleChange} value={title} type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Nội dung</label>
                        <div className="col-sm-9">
                          <ReactQuill
                            modules={this.modules}
                            formats={this.formats}
                            value={content}
                            onChange={this.handleChangeEditor} />
                        </div>
                      </div>
                      <div className="line" />
                      
                    
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Danh mục</label>
                        <div className="col-sm-9">
                        <select name={category.name} className="form-control mb-3" value={category.id} onChange={this.handleCategory}>
                          <option value='huong_dan' name="Hướng dẫn">Hướng dẫn</option>
                          <option value='quan_ly_web_site' name="Hướng dẫn">Quản lý website</option>
                          <option value='quan_ly_cua_hang' name="Hướng dẫn">Quản lý cửa hàng</option>
                          <option value='chinh_sach' name="Hướng dẫn">Chính sách</option>
                          
                          </select>
                        </div>
                      </div>
                      


                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Trạng thái</label>
                        <div className="col-sm-9">
                          <div className="i-checks">
                            <input type="checkbox"
                              onChange={this.handleChange}
                              name="isActive"
                              checked={isActive}
                              className="checkbox-template" />
                            <label htmlFor="checkboxCustom1"></label>
                          </div>
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <div className="col-sm-4 offset-sm-3">
                          <button type="reset" className="btn btn-secondary" style={{ marginRight: 2 }}>Cancel</button>
                          <button type="submit" className="btn btn-primary">Lưu bài viết</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/}
        <MyFooter></MyFooter>
      </div>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    add_Post: (token, newPost) => {
      dispatch(actAddPostRequest(token, newPost))
    },
    get_Post: (token, id) => {
      dispatch(actGetPostRequest(token, id))
    },
    edit_Post: (token, id, data) => {
      dispatch(actEditPostRequest(token, id, data))
    }
  }
}
export default connect(null, mapDispatchToProps)(ActionPost)