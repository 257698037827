export const config = {
  api: {
    url: 'https://ecommerce-api.ogisdigital.com/api/v1',
    url_public: 'https://ecommerce-api.ogisdigital.com/public-api/v1',
    url_cdn: 'https://ecommerce-api.ogisdigital.com/cdn-api/v1'
    //  url: 'http://localhost:8081/api/v1',
    //  url_public: 'http://localhost:8081/public-api/v1',
    // url_cdn: 'http://localhost:8080/cdn-api/v1'
  },
  firebaseConfig: {
    apiKey: "AIzaSyB3QRt4oO8jUPR9WCYRxo66oF7ij9FbX0Y",
    authDomain: "shopping-cart-a74dd.firebaseapp.com",
    projectId: "shopping-cart-a74dd",
    storageBucket: "shopping-cart-a74dd.appspot.com",
    messagingSenderId: "856282423668",
    appId: "1:856282423668:web:c6ca1a60e8a9cad775cc21",
    measurementId: "G-X8K3D4BX2R"
  }
}