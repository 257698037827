import React, { Component } from 'react'
import ActionPost from '../components/Content/Post/ActionPost'

export default class ActionPostPage extends Component {
  render() {
    const { match } = this.props;
    let id;
    if (match) {
      id = match.params.id;
    }
    return (
      <ActionPost id={id} ></ActionPost>
    )
  }
}
