import React, { Component } from 'react'
import ActionUserShop from '../components/Content/User/ActionUserShop'

export default class ActionUserShopPage extends Component {
  render() {
    const { match } = this.props;
    let id;
    let typeParam;
    if (match) {
      id = match.params.id;
      typeParam = match.params.typeParam;
    }
    return (
      <ActionUserShop id={id} typeParam ={typeParam}></ActionUserShop>
    )
  }
}
