import React, { Component } from 'react'
import MyFooter from '../../MyFooter/MyFooter'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { actAddProductRequest, actGetProductRequest, actEditProductRequest } from '../../../redux/actions/product';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import callApi from '../../../utils/apiCaller';
import { uploadS3 } from '../../../utils/upload'
import { currencyFormatVND,currencyVNDToNum } from '../../../utils/util'
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { SwatchesPicker } from 'react-color';
import { faTrashAlt,faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import DateTimePicker from 'react-datetime-picker';

let token;
let id;
const override = css`
    display: block;
    margin: 0 auto;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
`;
class ActionProduct extends Component {
  constructor(props) {
    super(props);
    var today = new Date();
    this.onDrop = (files) => {
      let data = [];
      if(this.state.dataGallery !== null) {
        data = this.state.dataGallery
        if(files && files.length !== 0) {
         files.forEach(item => data.push(item))
        }
      }
      if(!id){
        this.setState({
          files
        })
      } else {
        this.setState({
          files,
          dataGallery: data
        })
      }
    };
    this.state = {
      sizeImage:"512x512",
      oldDataGallery:[],
      isLoadColor:false,
      nameProduct: '',
      sku:"",
      price: "",
      unit:"",

      theNew: true,                                
      top: true,       
      size: [
       
      ],
      salePrice: "",
      discount: false,
      featured:true,
      until: new Date(today.getFullYear(), today.getMonth(), today.getDate()-1),
      variants: [       
                                       
      ],
      fPrices:[
{
  price:"",
  unit:"",
}
      ],
      numberAvailable: 0,
      categoryId: null,
      subCategoryId:null,
      desc: '',
      isActive: true,
      image: '',
      properties: {},
      producerId: null,
      redirectToProduct: false,
      dataCategories: [],
      dataSubCategories: [],
      dataProducer: [],
      img: null,
      loading: false,
      files: [],
      dataGallery: []
    };
    id = this.props.id
  }
  handleUntil = (value) => {
   
    this.setState({ until: value })
  }
  async componentDidMount() {
    token = localStorage.getItem('_auth');
    const resCategories = await callApi('categories?limit=100', 'GET', null, token);
    this.setState({
      dataCategories: resCategories.data.results,
      categoryId:resCategories.data.results[0]?.id
    })
    this.setState({
      dataSubCategories:resCategories.data.results[0]?.subCategory?resCategories.data.results[0]?.subCategory:[]
    })
    const resProducers = await callApi('producers?limit=100', 'GET', null, token);
    this.setState({
      dataProducer: resProducers.data.results
    })
    if (id) {
      const res = await callApi(`products/${id}`, 'GET', null, token);
      if (res && res.status === 200){
        var today = new Date();
        var tmpArr = this.state.dataCategories.filter(x => x.id === res.data.categoryId);
        this.setState({
          nameProduct: res.data.nameProduct,
          sku:res.data.sku,
          price: currencyFormatVND(res.data.price),
          unit:res.data.unit,
          theNew: res.data.new,                                
          top: res.data.top,       
          size: res.data.size,
          salePrice: currencyFormatVND(res.data.salePrice),
          discount: res.data.discount,
          featured:res.data.featured,
          until: res.data.until==="0001-01-01T00:00:00Z"?new Date(today.getFullYear(), today.getMonth(), today.getDate()-1):new Date(res.data.until) ,
          variants: res.data.variants,
          fPrices: res.data?.fPrices?res.data?.fPrices:[],
          
          numberAvailable: res.data.numberAvailable,
          categoryId: res.data.categoryId,
          subCategoryId:res.data.subCategoryId,
          dataSubCategories:tmpArr[0]?.subCategory?tmpArr[0]?.subCategory:[],
          desc: res.data.description,
          isActive: res.data.isActive,
          image: res.data.image,
          producerId: res.data.producerId,
          dataGallery: res.data.gallery,
          oldDataGallery:res.data.gallery,
          oldImage:res.data.image,
        })
      }
      }

  }
  handleChangeEditor = (value) => {
    this.setState({ desc: value })
  }

  handleChangeImage = (event) => {
    if (event.target.files[0]) {
      const img = event.target.files[0];
      this.setState(() => ({ img }));
    }
    const output = document.getElementById('output');
    output.src = URL.createObjectURL(event.target.files[0]);
  }
   
  handleChange = (event) => {
    const target = event.target;
    var value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
   if (name === "price" || name === "salePrice"){
    value = currencyFormatVND(value)
  
   }
    this.setState({
      [name]: value
    });
  }
  
  handleChangeSubCategory = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
  
    this.setState({
      subCategoryId: value,
    
    })
  }
  handleChangeCategory = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    var tmpArr = this.state.dataCategories.filter(x => x.id === value);
    this.setState({
      categoryId: value,
      subCategoryId:"none",
      dataSubCategories:tmpArr[0]?.subCategory?tmpArr[0]?.subCategory:[]
    })
  }

  handleChangeSelecProducer = (event) => {
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      producerId: value
    })
  } // get value of state

  handleChangeRemoveGallery = (index) => {
    const data = [...this.state.dataGallery];
     data.splice(index, 1)
    this.setState({
      dataGallery: data
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    
    const {dataGallery,oldDataGallery, nameProduct,sku, price,unit, numberAvailable, categoryId,subCategoryId, isActive, desc, properties, producerId,theNew,top,size,salePrice,discount,featured,until,variants } = this.state;
    var removeArray = oldDataGallery.filter(element => dataGallery.indexOf(element) === -1 )
 
    let fPrices = this.state.fPrices
    for (var i in fPrices) {
      fPrices[i].price = currencyVNDToNum(fPrices[i].price);
    }
    
    let { image, img } = this.state;
    let newFiles = dataGallery;
    this.setState({
      loading: true
    })
    if (img !== null && img !== image) {
      if (image.length >0){
        removeArray.push(image.slice())
      }
      image = await uploadS3("productImage"+Math.random(),img,token);
      
    }

    var fileLocals = []
    if(dataGallery && dataGallery.length) {
      dataGallery.forEach((item) => {
    
        if(typeof(item) === 'object'){
          fileLocals.push(item)
        }
      })
    }
    var responseUpload = []
    if (fileLocals.length > 0){
      var request = fileLocals.map((file) => { 
        return uploadS3("productGalery"+Math.random(),file,token) 
         .catch(e => console.log(`Error in sending email for`))
      })
      responseUpload =  await Promise.all(request).then(res=>{
        
        return res
          }).catch(err=>{
              console.log(err)
            
          })
    
    }

    newFiles = [...newFiles,...responseUpload]
  
    const newDesc = desc === '' ? null : desc;
    const newName = nameProduct === '' ? null : nameProduct;
    const newSKU = sku === '' ? null : sku;
    const newImage = image === '' ? null : image;
    const newGallery = newFiles && newFiles.length === 0 ? null : newFiles;
    const newProperties = properties === {} ? null : properties;
    const newNumberAvailable = parseInt(numberAvailable);
    const newPrice = currencyVNDToNum(price);
    const newSalePrice = currencyVNDToNum(salePrice);

    var tmpArr = this.state.dataCategories.filter(x => x.id === categoryId);
    var subCategory = {}
    if (subCategoryId!=="none"){
      const arr = this.state.dataSubCategories.filter(x => x.subId === subCategoryId);
      subCategory = arr.length>0?arr[0]:{}
    }
   
    let newGalleryFinal = [];
    if(newGallery && newGallery.length) {
      newGallery.forEach((item) => {
        if(typeof(item) === 'string'){
          newGalleryFinal.push(item)
        }
      })
    }
    if (!id) {
      const newProduct = {
        isActive,
        nameProduct: newName,
        sku:newSKU,
        price:newPrice,
        unit,
        fPrices,
        categoryId:categoryId,
        subCategoryId:subCategoryId,
        subCategory,
        categories:tmpArr[0],
        image: newImage,
        gallery: newGalleryFinal,
        description: newDesc,
        properties: newProperties,
        producerId,
        numberAvailable: newNumberAvailable,  

        new: theNew,                                
        top: top,       
        size: size,
        salePrice: newSalePrice,
        discount: discount,
        featured:featured,
        until: until,
        variants: variants,
       
      }
     
      this.props.add_Product(token, newProduct);
      this.setState({
        nameProduct: '',
        sku:"",
        image: '',
        desc: '',
        properties: {},
        dataGallery: newGalleryFinal,
        loading: false,
        redirectToProduct: true,
      })
    } else {
      const editProduct = {
        isActive,
        nameProduct: newName,
        sku:newSKU,
        price:newPrice,
        unit,
        numberAvailable: newNumberAvailable,
        categoryId:categoryId,
        categories:tmpArr[0],
        subCategoryId,
        subCategory,
        image: newImage,
        gallery: newGalleryFinal,
        description: newDesc,
        properties: newProperties,
        producerId,
        new: theNew,                                
        top: top,       
        size: size,
        salePrice: newSalePrice,
        discount: discount,
        featured:featured,
        until: until,
        variants: variants,
        fPrices,
        removeArray:removeArray
      }
      await this.props.edit_Product(token, id, editProduct);
      this.setState({
        loading: false,
        redirectToProduct: true,
        dataGallery: newGalleryFinal
      })
    }
    
  }
  modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  };

  formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  getValueSizeCheckBox=(name)=>{
    const index = this.state.size?.indexOf(name);
    if (index === -1) {
     
      return false
    }
    
    return true
  }
  handleChangeSize = (event) => {
    const target = event.target;
    var copy = this.state.size.slice()
    if (target.checked){
      const index = copy.indexOf(target.name);
        if (index === -1) {
          if (target.name === "Free size"){
            copy =[]
          }else{
            const index2 = copy.indexOf("Free size");
            if(index2>-1){
              copy.splice(index2, 1);
            }
            
          }
        copy.push(target.name)
        this.setState({
          size: copy
        })
      }
    }else{
      const index = copy.indexOf(target.name);
      if (index > -1) {
        copy.splice(index, 1);
        this.setState({
          size: copy,
         
        })
      }
    }
  
  }
  
  removeColor = (index)=>{
    var copy = this.state.variants.slice()
    copy.splice(index, 1);
    this.setState({
        isLoadColor:!this.state.isLoadColor,
        variants: copy
      }) 
  }
  handleChangeComplete = (color) => {
    var copy = this.state.variants.slice()
    if(copy.length>6){
      alert("Chỉ được thêm tối đa 6 màu")
    }else{
      copy.push({
        color:color.hex
      })
      this.setState({
        isLoadColor:!this.state.isLoadColor,
        variants: copy
      }) 
    }
    
    
 
  };

  handleChangeFprice = (event) => {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    const myArr = name.split("-|-");
    const price = currencyFormatVND(value)
    const index = parseInt(myArr[1],10)
    var copy = this.state.fPrices.slice()
    copy[index] = {
      price:price,
      unit:copy[index].unit,
    }
    this.setState({
      fPrices:[...copy]
    }) 
  }
  handleChangeFunit = (event) => {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    const myArr = name.split("-|-");
    const unit = value
    const index = parseInt(myArr[1],10)
    var copy = this.state.fPrices.slice()
    copy[index] = {
      unit:unit,
      price:copy[index].price,
    }
    this.setState({
      fPrices:[...copy]
    }) 
  }

  removeFprice = (index)=>{
    var copy = this.state.fPrices.slice()

    if (copy.length <2)
    {
      alert("Phải có tối thiểu 1 giá.")
    }else{
      copy.splice(index, 1);
    this.setState({
        fPrices: [...copy]
      }) 
    }
    
  }
  
 
  handleAddPrice = () => {
    if(this.state.fPrices.length>6){
      alert("Chỉ được thêm tối đa 6 giá khác nhau")
    }else{
      this.setState({
        fPrices: [{
          price:0,
          unit:"",
        }, ...this.state.fPrices]
      }) 
    }
  };
  handleAddFpriceComplete = (price,unit) => {
    
    var copy = this.state.fPrices.slice()
    if(copy.length>6){
      alert("Chỉ được thêm tối đa 6 giá khác nhau")
    }else{
      copy.push({
        price:price,
        unit:unit,
      })
      this.setState({
        isLoadColor:!this.state.isLoadColor,
        fPrices: copy
      }) 
    }
  };
  render() {
    const { dataGallery, nameProduct,sku, loading, price,unit,fPrices, numberAvailable, categoryId,subCategoryId, isActive, image, desc, producerId, redirectToProduct, dataCategories,dataSubCategories, dataProducer,theNew,top,size,salePrice,discount,featured,variants } = this.state;
    let files;
    if(dataGallery && dataGallery.length !== 0) {
      files = dataGallery.map((file, index) => {
        return(
        <span key={index} >
          {
            typeof(file) !== 'object' ? 
            <div  >
            <img src={file} style={{height: 100, width: 75, marginLeft:20}} alt="notfound"/> 
            <FontAwesomeIcon onClick={this.handleChangeRemoveGallery.bind(this,index)} color= 'Tomato' icon={faTrashAlt} style={{ marginLeft:-10, marginBottom:50, textColor:'red'}}/>
            </div>
            :
            <div >
            <img src={URL.createObjectURL(file)} style={{height: 100, width: 75, marginLeft:20}} alt="notfound"/>
            <FontAwesomeIcon onClick={this.handleChangeRemoveGallery.bind(this,index)} color= 'Tomato' icon={faTrashAlt} style={{ marginLeft:-10, marginBottom:50, textColor:'red'}}/>
            </div>
          }
        </span>
        )
      })
    } else {
      // files = this.state.files.map(file => (     
      //   <img src={URL.createObjectURL(file)} style={{height: 100, width: 75, marginLeft:20}} alt="notfound" />
        
      // ));
    }
    if (redirectToProduct) {
      return <Redirect to='/products'></Redirect>
    }
    return (
      <div className="content-inner">
        {/* Page Header*/}
        <div className='sweet-loading'>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={30}
            color={'#796aeebd'}
            loading={loading}
          />
        </div>
        <header className="page-header">
          <div className="container-fluid">
          {!id?<h2 className="no-margin-bottom">Thêm sản phẩm</h2>:<h2 className="no-margin-bottom">Cập nhật sản phẩm</h2>}
            
          </div>
        </header>
        {/* Breadcrumb*/}
        <div className="breadcrumb-holder container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Trang Chủ</a></li>
            <li className="breadcrumb-item active">Sản Phẩm</li>
          </ul>
          <label className="col-sm-12 form-control-label" style={{color:"red"}}>SEO - Các thông tin bên dưới sẽ hiển thị trong kết quả tìm kiếm của công cụ tìm kiếm (Google, Bing,...). Sử dụng đoạn văn mô tả và từ khóa để cải thiện thứ tự xếp hạng cho website của bạn </label>
          <label className="col-sm-12 form-control-label" style={{color:"red"}}>Để hình ảnh được tải nhanh, đẹp bạn nên để kích thước chuẩn {this.state.sizeImage}px , định dạng jpeg </label>
        </div>
        {/* Forms Section*/}
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              {/* Form Elements */}
              
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h3 className="h4">Thông tin sản phẩm</h3>
                   
                  </div>
                  <div className="card-body">
                    <form className="form-horizontal" onSubmit={(event) => this.handleSubmit(event)} >
                      
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Tên sản phẩm *</label>
                        <div className="col-sm-9">
                          <input name="nameProduct" onChange={this.handleChange} value={nameProduct} type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Mã sản phẩm (SKU)</label>
                        <div className="col-sm-9">
                          <input name="sku" onChange={this.handleChange} value={sku} type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Danh mục *</label>
                        <div className="col-sm-9">
                          <select className="form-control mb-3" name="categoryId" value={categoryId} onChange={this.handleChangeCategory} >
                            {
                              dataCategories.map((item, index) => {
                                return (
                                  <option key={index} value={item.id} >{item.nameCategory}</option>
                                )
                              }) 
                            }
                          </select>
                        </div>
                      </div>

                      {dataSubCategories.length>0?
                        <div>
                      
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Danh mục con</label>
                        <div className="col-sm-9">
                          <select className="form-control mb-3" name="subCategoryId" value={subCategoryId} onChange={this.handleChangeSubCategory} >
                            {dataSubCategories.length>0? <option key={"none"} value={"none"} >Chọn danh mục con</option>:null}
                            {
                              dataSubCategories.map((item, index) => {
                                return (
                                  <option key={index} value={item.subId} >{item.nameCategory}</option>
                                )
                              }) 
                            }
                          </select>
                        </div>
                      </div></div>:null}

       
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Thương hiệu</label>
                        <div className="col-sm-9">
                          <select className="form-control mb-3" name="producerId" value={producerId} onChange={this.handleChangeSelecProducer} >
                            {
                             categoryId !==0 && dataProducer && dataProducer.length ? dataProducer.map((item, index) => {
                                return (
                                  <option key={index} value={item.id} >{item.name}</option>
                                )
                              }) : null
                            }
                          </select>
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Giá *</label>
                        {/* <div className="col-sm-3">
                          <input name="price" onChange={this.handleChange} value={price} type="text" className="form-control" />
                        </div>
                        <label style={{textAlign: 'center'}} className="col-sm-3 form-control-label">Đơn vị tính </label>
                        <div className="col-sm-3">
                        <input placeholder="1kg, 300 gram, 1 cái, 1 bó..." name="unit" onChange={this.handleChange} value={unit} type="text" className="form-control" />
                        </div> */}
                      </div>
                      
                      
                     

                      <div className="form-group row">
                      {fPrices.length>0 && this.state.fPrices.map( ( item, i ) =>{
                              return <div
                              key={  '-i' + i }
                               className="form-group row" style={{marginLeft:40}}>
                                    <label className="col-sm-2 form-control-label">Giá *</label>
                                    <div className="col-sm-3">
                                      <input name={"price-|-"+i} onChange={this.handleChangeFprice} value={item.price} type="text" className="form-control" />
                                    </div>
                                    <label style={{textAlign: 'center'}} className="col-sm-3 form-control-label">Đơn vị tính </label>
                                    <div className="col-sm-3">
                                    <input placeholder="1kg, 300 gram, 1 cái, 1 bó..." name={"unit-|-"+i} onChange={this.handleChangeFunit} value={item.unit} type="text" className="form-control" />
                                    
            
                                    </div>
                                    <FontAwesomeIcon 
                                    onClick={this.removeFprice.bind(this,i)}
                                     color= 'Tomato' icon={faTrashAlt} 
                                     style={{ marginTop:10}}/>
                                    
                                  </div>
                            })
                            }
                    
                        <label style={{cursor: 'pointer'}} className="col-sm-9 form-control-label"
                          onClick={this.handleAddPrice.bind(this)}><FontAwesomeIcon 
                          
                            color= '#48964b' icon={faPlusCircle} 
                            style={{marginBottom:0, marginRight:5, marginLeft:30}}/>
                           
                       Thêm giá (Thay vì bán 1kg, bạn có thể bán 300gr, 200 gram ... )</label>
                       
                        
                       </div>

                            
                      <div className="line" />

                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Giảm giá</label>
                        <div className="col-sm-3">
                        <div className="i-checks">
                            <input type="checkbox"
                              onChange={this.handleChange}
                              name="discount"
                              checked={discount} className="checkbox-template" />
                          </div>
                         
                        </div>
                        {discount?
                          <label className="col-sm-3 form-control-label">Giảm giá đến ngày</label>
                          :null}
                          {discount?<div className="col-sm-3">
                          <DateTimePicker
                          format={"dd/MM/y"}
                       
                          
                          onChange={this.handleUntil}
                          value={this.state.until}
                        />
                        
                        </div>:null}
                        
                       </div>
                    
                     

                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Giảm giá (%)</label>
                        <div className="col-sm-3">
                          <input placeholder="25" name="salePrice" onChange={this.handleChange} value={salePrice} type="number" className="form-control" />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Tổng số sản phẩm trong kho </label>
                        <div className="col-sm-3">
                        <input name="numberAvailable" onChange={this.handleChange} value={numberAvailable} type="number" className="form-control" />
                        </div>
                      </div>
                      <div className="line" style={{display: "none"}} />
                     
                       <div style={{display: "none"}} className="form-group row">
                        <label className="col-sm-3 form-control-label">Kích cỡ</label>
                        
                              <div className="col-sm-9">
                              <div className="i-checks">
                                      <input type="checkbox"
                                        onChange={this.handleChangeSize}
                                        name="Free size"
                                        checked={this.getValueSizeCheckBox("Free size")} className="checkbox-template" />
                                        <label className="col-sm-3 form-control-label">Free size</label>
                                    </div>
                                  <div className="i-checks">
                                      <input type="checkbox"
                                        onChange={this.handleChangeSize}
                                        name="XS"
                                        checked={this.getValueSizeCheckBox("XS")} className="checkbox-template" />
                                        <label className="col-sm-3 form-control-label">XS</label>
                                    </div>
                                    <div className="i-checks">
                                      <input type="checkbox"
                                        onChange={this.handleChangeSize}
                                        name="S"
                                        checked={this.getValueSizeCheckBox("S")} className="checkbox-template" />
                                        <label className="col-sm-3 form-control-label">S</label>
                                    </div>
                                    <div className="i-checks">
                                      <input type="checkbox"
                                        onChange={this.handleChangeSize}
                                        name="M"
                                        checked={this.getValueSizeCheckBox("M")} className="checkbox-template" />
                                        <label className="col-sm-3 form-control-label">M</label>
                                    </div>
                                    <div className="i-checks">
                                      <input type="checkbox"
                                        onChange={this.handleChangeSize}
                                        name="L"
                                        checked={this.getValueSizeCheckBox("L")} className="checkbox-template" />
                                        <label className="col-sm-3 form-control-label">L</label>
                                    </div>
                                    <div className="i-checks">
                                      <input type="checkbox"
                                        onChange={this.handleChangeSize}
                                        name="XL"
                                        checked={this.getValueSizeCheckBox("XL")} className="checkbox-template" />
                                        <label className="col-sm-3 form-control-label">XL</label>
                                    </div>
                                    <div className="i-checks">
                                      <input type="checkbox"
                                        onChange={this.handleChangeSize}
                                        name="XXL"
                                        checked={this.getValueSizeCheckBox("XXL")} className="checkbox-template" />
                                        <label className="col-sm-3 form-control-label">XXL</label>
                                    </div>
                              </div>
                              
                       </div>
                       <div style={{display: "none"}} className="line" />



                       <div className="form-group row" style={{display: "none"}}>
                        <label className="col-sm-3 form-control-label">Màu sắc</label>
                        <div className="col-sm-3">
                        <SwatchesPicker
                         className="col-sm-9 form-control-label"
                         
                          onChangeComplete={ this.handleChangeComplete }
                        />
                         </div>
                        <div className="col-sm-3">
                        {!this.state.isLoadColor?
                        <div className="col-sm-3" >
                        { this.state.variants?.map( ( vari, i ) =>
              
                          <div 
                          onClick={this.removeColor.bind(this,i)}
                          key={  '-' + i }
                           style={ { 
                            backgroundColor: vari.color ,
                            marginLeft:20,
                            marginTop:10,
                            height: 24,
                            width: 40,
                            cursor: "pointer",
                            position: "relative",
                            outline: "none",
                            
                            overflow: "hidden",
                            borderRadius: 5,
                            borderWidth: 10,
                            borderColor: '#313231',
 } }
                            
                            >
                            {vari.color==="#ffffff"?<label style ={{color:"#313231",cursor: "pointer",}} className="col-sm-3 form-control-label">x</label>
                          :<label style ={{color:"white",cursor: "pointer",}} className="col-sm-3 form-control-label">x</label>
                          }
                          </div>

                                            
                                        ) }
                        </div>:<div className="col-sm-3" >
                        { this.state.variants.map( ( vari, i ) =>
              
                          <div  
                          onClick={this.removeColor.bind(this,i)}
                          key={  '-' + i }
                          style={ { 
                            backgroundColor: vari.color ,
                            marginLeft:20,
                            marginTop:10,
                            height: 24,
                            width: 40,
                            cursor: "pointer",
                            position: "relative",
                            outline: "none",
                            
                            overflow: "hidden",
                            borderRadius: 5,
                            borderWidth: 10,
                            borderColor: '#313231',
                        } }
                            
                            >
                            {vari.color==="#ffffff"?<label style ={{color:"#313231",cursor: "pointer",}} className="col-sm-3 form-control-label">x</label>
                          :<label style ={{color:"white",cursor: "pointer",}} className="col-sm-3 form-control-label">x</label>
                          }
                            <label style ={{color:"white",cursor: "pointer",}} className="col-sm-3 form-control-label">x</label>
                          </div>  
                            

                                            
                                        ) }
                        </div>}
                        </div>
                      </div>


                      <div className="line" />
                      <div className="form-group row">
                        <label htmlFor="fileInput" className="col-sm-3 form-control-label">Hình ảnh chính (kích thước hình ảnh là {this.state.sizeImage} px)*</label>
                        <div className="col-sm-9">
                          <input type="file" onChange={this.handleChangeImage} className="form-control-file" />
                          <div className="fix-cart-product"
                           style={{ marginLeft:0, marginTop:20}}>
                            <img className="fix-cart-product" src={image || 'http://via.placeholder.com/'+this.state.sizeImage} id="output" className="fix-img-product" alt="avatar" />
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label pt-50">Hình ảnh sản phẩm (kích thước hình ảnh là {this.state.sizeImage} px)</label>
                        <div className="col-sm-9">
                            <Dropzone 
                            onDrop={this.onDrop}
                            addRemoveLinks={true}
                            >
                            {({getRootProps, getInputProps}) => (                            
                              <section className="container" style={{border: '1px dotted'}}>
                                <div {...getRootProps({className: 'dropzone'})}>
                                  <input {...getInputProps()} />
                                  <h2>Nhấn vào đây để tải lên hình ảnh sản phẩm, tối đa 4 hình, kích thước  chuẩn, đẹp là {this.state.sizeImage}</h2>
                                </div>
                                <aside>
                                  {files}
                                </aside>
                              </section>
                          )}
                        </Dropzone>
                        </div>
                       
                      </div>
                      
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Mô tả</label>
                        <div className="col-sm-9">
                          <ReactQuill
                            modules={this.modules}
                            formats={this.formats}
                            value={desc}
                            onChange={this.handleChangeEditor} />
                        </div>
                      </div>
        
                      
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Sản phẩm mới</label>
                        <div className="col-sm-9">
                        <div className="i-checks">
                            <input type="checkbox"
                              onChange={this.handleChange}
                              name="theNew"
                              checked={theNew} className="checkbox-template" />
                          </div>
                        </div>
                       </div>

                       <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Sản phẩm nổi bật</label>
                        <div className="col-sm-9">
                        <div className="i-checks">
                            <input type="checkbox"
                              onChange={this.handleChange}
                              name="top"
                              checked={top} className="checkbox-template" />
                          </div>
                        </div>
                       </div>

                       <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Sản phẩm được yêu thích</label>
                        <div className="col-sm-9">
                        <div className="i-checks">
                            <input type="checkbox"
                              onChange={this.handleChange}
                              name="featured"
                              checked={featured} className="checkbox-template" />
                          </div>
                        </div>
                       </div>
                       <div className="line" />
                       <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Trạng thái hoạt động<br /></label>
                        <div className="col-sm-9">
                          <div className="i-checks">
                            <input type="checkbox"
                              onChange={this.handleChange}
                              name="isActive"
                              checked={isActive} className="checkbox-template" />
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-4 offset-sm-3">
                          <button type="reset" className="btn btn-secondary" style={{ marginRight: 2 }}>Hủy</button>
                          <button type="submit" className="btn btn-primary">Lưu sản phẩm</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/}
        <MyFooter></MyFooter>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add_Product: (token, newProduct) => {
      dispatch(actAddProductRequest(token, newProduct))
    },
    get_Product: (token, id) => {
      dispatch(actGetProductRequest(token, id))
    },
    edit_Product: (token, id, data) => {
      dispatch(actEditProductRequest(token, id, data))
    }
  }
}
export default connect(null, mapDispatchToProps)(ActionProduct)