import React, { Component } from 'react'
import Print from '../components/Print/Print'


export default class PrintPage extends Component {
  render() {
    return (
      <Print></Print>
    )
  }
}
