// import firebase from 'firebase/app';
// import 'firebase/storage';
import { config } from '../config'

// firebase.initializeApp(config.firebaseConfig);
// const storage = firebase.storage();
const storage = null;
const firebase = null;
export {
  // storage, firebase as default
  storage, firebase as default
}
