import React, { Component } from 'react'
import MyFooter from '../../MyFooter/MyFooter'
import { connect } from 'react-redux'
import callApi from '../../../utils/apiCaller';
import { actAddOrderRequest, actGetOrderRequest, actEditOrderRequest } from '../../../redux/actions/order';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import CheckoutDialog from '../../Custom/CheckoutDialog'
let token;
let id;
const ref = React.createRef();
class ActionOrder extends Component {
  constructor(props) {
    
    super(props);
    this.state = {
      staff:props.staff,
      dialog: false,
      transaction:{
      },
      fullName: '',
      address: '',
      note: '',
      noteForShip: '',
      phone: '',
      totalAmount: 0,
      itemAmount: 0,
      promoTotal: 0,
      shippingTotal: 0,
      paypalCode: '',
      status: 'Unconfirm',
      isPaid: false,
      code: "",
      isPaymentOnline: false,
      redirectToOrder: false,
      provinceData: '01',
      stateData: '001',
      provinces: null,
      states: null,
      dataOrderDetails: []
    };
    id = this.props.id
  }
   openInNewTab = (url) => {

    var width  = 660;
    var height = 92040;
    var left   = 460;
    var top    = 100;
    var params = 'width='+width+', height='+height;
    params += ', top='+top+', left='+left;
    params += ', directories=no';
    params += ', location=no';
    params += ', menubar=no';
    params += ', resizable=no';
    params += ', scrollbars=no';
    params += ', status=no';
    params += ', toolbar=no';
     var newWin  = window.open(url, url, params);

       // const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
       // if (newWindow) newWindow.opener = null
     }
  async componentDidMount() {
    token = localStorage.getItem('_auth');
    
    if (id) {
      const res = await callApi(`orders/${id}`, 'GET', null, token);
      //const myStates = await callApi(`provinces/${res.data.address.codeProvince}/states`, 'GET', null);
        this.setState({
       
        provinces: [],
        states: [],
        fullName: res.data.fullName,
        address: res.data.address.house,
        province: res.data.address.province.name,
        state: res.data.address.state.name,
        ward:res.data.address.ward.name,
        paypalCode: res.data.paypalCode,
        provinceData: res.data.address.codeProvince,
        stateData: res.data.address.codeState,
        note: res.data.note,
        noteForShip:res.data.noteForShip,
        phone: res.data.phone,
        totalAmount: res.data.totalAmount,
        itemAmount: res.data.itemAmount,
        promoTotal: res.data.promoTotal,
        shippingTotal: res.data.shippingTotal,
        status: res.data.status,
        isPaid: res.data.isPaid,
        code: res.data.orderid,
        isPaymentOnline: res.data.isPaymentOnline,
        dataOrderDetails: res.data.orderDetails.results,
        createdat:res.data.createdat
      })
    } else {
      
      this.setState({
        provinces: [],
        states: []
      })
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { isPaid, status, note,noteForShip } = this.state;
      const editOrder = {
        note,
        noteForShip,
        isPaid,
        status
      }
      await this.props.edit_order(token, id, editOrder);
      this.setState({
        redirectToOrder: true
      })

  }

  sumTotal = (itemAmount, shippingTotal, promoTotal) => {
    const newitemAmount = itemAmount ? itemAmount : 0;
    const newShippingTotal = shippingTotal ? shippingTotal : 0;
    const newpPomoTotal = promoTotal ? promoTotal : 0;

    const result = parseInt(newitemAmount) + parseInt(newShippingTotal) - parseInt(newpPomoTotal);
    if (result < 0) {
      return toast.error('ERROR! Total amount can not < 0');
    }
    return result;
  }

  handleChangeSelectProvince = async (event) => {
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const res = await callApi(`provinces/${value}/states`, 'GET', null)
    this.setState({
      states: res.data,
      provinceData: value,
      stateData: res.data[0].code
    })
  } //get value of province
  handleChangeSelectState = (event) => {
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      stateData: value
    })
  } // get value of state
  closeDialog = () => {
    this.setState({
      dialog: false
    })
}
findIndex = function ( array, cb ) {
  if ( array ) {
      for ( let i = 0; i < array.length; i++ ) {
          if ( true === cb( array[ i ] ) )
              return i;
      }
  }
  return 0;
}
  render() {
    const {  dataOrderDetails, fullName, address, note,noteForShip, code, phone, totalAmount, promoTotal, shippingTotal, createdat, isPaid, isPaymentOnline, status, redirectToOrder } = this.state;
    let orderDetailAmount = 0;
    if (dataOrderDetails?.length > 0) {
      orderDetailAmount = dataOrderDetails.reduce((sum, item) => {
        return sum +=  item.price
      }, 0)
    }
    if (redirectToOrder) {
      return <Redirect to='/orders'></Redirect>
    }

    const transaction= {
    
      classes:{
        paper: {
        borderRadius: 20,
        textAlign: 'center',
        size: 'auto'
    }},
    createdat:createdat,
    staff:this.state.staff,
    customer_name:fullName,
    customer_address:address+", "+this.state.ward+", "+ this.state.state+", "+this.state.province,
    customer_phone:phone,
    customer_note:note,
       _id:code,
      products:dataOrderDetails,
      subtotal:orderDetailAmount.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } ) +"đ",
      shippingTotal:shippingTotal.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } ) + "đ",
      discount:promoTotal.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } ) + "đ",
      grandtotal:totalAmount.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } ) + "đ",
    }
    return (
      <div className="content-inner">
        {/* Page Header*/}
        <CheckoutDialog
                        open={this.state.dialog}
                        { ...transaction }
                        onClose={this.closeDialog}
                    />
        <header className="page-header">
          <div className="container-fluid">
            <h2 className="no-margin-bottom">Đơn hàng</h2>
          </div>
        </header>
        {/* Breadcrumb*/}
        <div className="breadcrumb-holder container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Trang chủ</a></li>
            <li className="breadcrumb-item active">Đơn hàng</li>
          </ul>
        </div>
        
        {/* Forms Section*/}
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              {/* Form Elements */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h3 className="h4">Thông tin đơn hàng</h3>
                    <button style={{marginLeft:20}}
                     onClick={() => {
                       
                       this.setState({dialog:true})
                      
                       }}>
                     In Đơn Hàng
                      </button>
                  </div>
                  <div className="card-body">
                  {
                        id ? <div>
                       <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label" style={{paddingTop: 50}}>Sản phẩm</label>
                        <div className="col-sm-9">
                        <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover" ref={ref}>
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Tên sản phẩm</th>
                            <th>SKU</th>
                            <th>Hình ảnh</th>
                            <th>Tùy chọn</th>
                  
                            <th>Số lượng</th>
                            <th>Đơn giá</th>
                            <th>Thành tiền</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataOrderDetails && dataOrderDetails.length ? dataOrderDetails.map((item, index) => {
                            const _index = this.findIndex( item.product.fPrices, _item => _item.unit === item.unit );
                            const _price = item.product.fPrices[_index].price
                            return (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{item.nameProduct}</td>
                                <td>{item.sku}</td>
                                <td>
                                  <div className="fix-cart">
                                    <img src={item && item.image ? item.image : null} className="fix-img" alt="not found" />
                                  </div>
                                </td>
                                <td>

                                                    {item.color !=="none"?
                                                    <div style={ {
                                                                                    backgroundColor: item.color ,
                                                                                    marginRight:10,
                                            
                                                                                height: 20,
                                                                                width: 20,
                                                                              
                                                                                border: '1px solid black', borderRightColor: 'black'
                                                                                } }> 
                                                    </div>:null}
                                                            
                                                            {item.size?item.size:null}
                                                                {item.unit?item.unit:null}
                                                               
                                                            
                                </td>
                                <td>{item.quantity}</td>
                                <td>{_price*item.quantity-item.price>0?(item.price/item.quantity).toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } ):_price.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } )}{_price*item.quantity-item.price>0?<sup style={{textDecoration: "line-through"}}>{_price.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } )}</sup>:<span></span>}</td>
                                <td>{item.price.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } )} </td>
                              </tr>
                            )
                          }) : null}
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{width:210}}>
                            <span style={{fontSize: 16}}>Tiền hàng: {orderDetailAmount.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } )}đ</span>
                            <br/>
                            <span style={{fontSize: 16}}>Phí ship: {shippingTotal.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } )}đ</span>
                            <br/>
                            <span style={{fontSize: 16}}>Giảm giá: {promoTotal.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } )}đ</span>
                            <br/>
                            <br/>
                            <b style={{fontSize: 16}}>Tổng: {totalAmount.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } )}đ</b>
                           

                        </td>
                        </tbody>
                      </table>
                    </div>
                  </div>
                        </div>
                      </div>
                          <div className="line" />
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">Mã đơn</label>
                            <div className="col-sm-3">
                              <input disabled value={code} type="text" disabled className="form-control" />
                            </div>
                            {/* <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}} >Paypal Code</label>
                            <div className="col-sm-3">
                              <input disabled value={paypalCode} name="paypalCode" className="form-control" />
                            </div> */}
                          </div>
                          {/* <div className="line" />
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">Tiền hàng</label>
                            <div className="col-sm-3">
                              <input name="itemAmount" disabled value={itemAmount} onChange={this.handleChange} type="number" className="form-control" />
                            </div>
                            <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Phí vận chuyển</label>
                            <div className="col-sm-3">
                              <input name="shippingTotal" disabled value={shippingTotal} onChange={this.handleChange} type="number" className="form-control" />
                            </div>
                          </div> */}
                          {/* <div className="line" />
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">Giảm giá</label>
                            <div className="col-sm-3">
                              <input name="shippingTotal" disabled value={promoTotal} onChange={this.handleChange} type="number" className="form-control" />
                            </div>
                            <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Tổng</label>
                            <div className="col-sm-3">
                              <input disabled name="totalAmount" value={totalAmount} onChange={this.handleChange} type="number" className="form-control" />
                            </div>
                          </div> */}
                        </div>
                          :
                          <div>
                          {/* <div className="line" />
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">Tiền hàng</label>
                            <div className="col-sm-3">
                              <input name="itemAmount"  value={itemAmount} onChange={this.handleChange} type="number" className="form-control" />
                            </div>
                            <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Phí vận chuyển</label>
                            <div className="col-sm-3">
                              <input name="shippingTotal" value={shippingTotal} onChange={this.handleChange} type="number" className="form-control" />
                            </div>
                          </div>
                          <div className="line" />
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">Giảm giá</label>
                            <div className="col-sm-3">
                              <input name="shippingTotal" value={promoTotal} onChange={this.handleChange} type="number" className="form-control" />
                            </div>
                            <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Tổng</label>
                            <div className="col-sm-3">
                              <input name="totalAmount" value={totalAmount} onChange={this.handleChange} type="number" className="form-control" />
                            </div>
                          </div> */}
                          </div>
                      }
                      <div className="line" />
                    <form className="form-horizontal" onSubmit={(event) => this.handleSubmit(event)} >
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Tên khách hàng</label>
                        <div className="col-sm-9">
                          <input disabled type="text" name="fullName" value={fullName} onChange={this.handleChange} className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Tỉnh, Thành phố</label>
                        <div className="col-sm-3">

                        <input disabled name="address" value={this.state.province} onChange={this.handleChange} type="text" className="form-control" />
                          {/* <select
                            name="provinces" value={provinceData}
                            onChange={this.handleChangeSelectProvince}
                            className="form-control mb-3">
                            {
                              (provinces && provinces.length) ? provinces.map((province, index) => {
                                return (
                                  <option key={index} value={province.code}>{province.name}</option>
                                )
                              }
                              ) : null
                            }
                          </select> */}
                        </div>
                        <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Quận, Huyện</label>
                        <div className="col-sm-3">
                        <input disabled name="address" value={this.state.state} onChange={this.handleChange} type="text" className="form-control" />
                        </div>
                        {/* <div className="col-sm-3">
                          <select
                            name="state" value={stateData}
                            onChange={this.handleChangeSelectState}
                            className="form-control mb-3"
                          >
                            {
                              (states && states.length) ? states.map((state, index) => {
                                return (
                                  <option key={index} value={state.code}>{state.name}</option>
                                )
                              }
                              ) : null
                            }
                            </select>
                        </div> */}
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Địa chỉ</label>
                        <div className="col-sm-3">
                          <input disabled name="address" value={address} onChange={this.handleChange} type="text" className="form-control" />
                        </div>
                        <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Số điện thoại</label>
                        <div className="col-sm-3">
                          <input disabled name="phone" value={phone} onChange={this.handleChange} type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Lưu ý từ khách hàng</label>
                        <div className="col-sm-9">
                          <textarea name="note" value={note} onChange={this.handleChange} type="text" rows={3} className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Lưu ý cho Shipper</label>
                        <div className="col-sm-9">
                          <textarea placeholder="Giao buổi tối, thu hộ (COD) 500K...."name="noteForShip" value={noteForShip} onChange={this.handleChange} type="text" rows={3} className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Trạng thái</label>
                        <div className="col-sm-9">
                          <select name="account" className="form-control mb-3" name="status" value={status} onChange={this.handleChange}>
                            <option value='Unconfirm'>Chưa xác nhận</option>
                            <option value='Confirm'>Đã xác nhận</option>
                            <option value='Waitship' >Đã đóng gói, chờ giao ship</option>
                            <option value='Shipping' >Đang vận chuyển</option>
                            <option value='Complete' >Đã giao</option>
                            <option value='Canceled' >Hủy</option>
                          </select>
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Đã thanh toán</label>
                        <div className="col-sm-3">
                          <div className="i-checks">
                            <input type="checkbox"
                              onChange={this.handleChange}
                              name="isPaid"
                              checked={isPaid}
                              className="checkbox-template" />
                          </div>
                        </div>
                        
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <div className="col-sm-4 offset-sm-3">
                          <button type="reset" className="btn btn-secondary" style={{ marginRight: 2 }}>Cancel</button>
                          <button type="submit" className="btn btn-primary">Lưu thay đổi</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/}
        <MyFooter></MyFooter>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add_order: (token, newOrder) => {
      dispatch(actAddOrderRequest(token, newOrder))
    },
    get_order: (token, id) => {
      dispatch(actGetOrderRequest(token, id))
    },
    edit_order: (token, id, data) => {
      dispatch(actEditOrderRequest(token, id, data))
    }
  }
}
const mapStateToProps = (state) => {
   if (state.infoMe?.role && state.infoMe?.role?.description){
    return {
      staff:state.infoMe?.role?.description.toUpperCase() +" "+ state.infoMe?.name.toUpperCase()
   }
  }
  
}
export default connect(mapStateToProps, mapDispatchToProps)(ActionOrder)
