import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import GradientButton from "./GradientButton";
import Box from "@material-ui/core/Box";
import ReactToPrint from "react-to-print";
import OrderPrint from "./OrderPrint";

const styles = {
    root: {
        width: 480,
        paddingBottom: 40,
    },
    paper: {
        borderRadius: 20,
        textAlign: 'center'
    },
    box: {
        width: '100%'
    },
    button: {
        marginLeft: 25,
        marginRight: 25
    }
}

class CheckoutDialog extends React.Component {
    render() {
        
        const { staff,customer_name,customer_address, customer_phone,customer_note,classes, open, createdat, _id, products, subtotal, discount, grandtotal, onClose } = this.props

        if (!products) return <></>

        return (
            <Dialog
                open={open}
                onClose={onClose}
                classes={{
                    paper: classes.paper
                }}>
                <DialogContent className={classes.root}>
                    <div>
                        <OrderPrint
                            staff={staff}
                            customer_name={customer_name}
                            customer_address={customer_address}
                            customer_phone={customer_phone}
                            customer_note={customer_note}
                            createdat={createdat}
                            id={_id}
                            products={products}
                            subtotal={subtotal}
                            discount={discount}
                            grandtotal={grandtotal}
                            ref={el => (this.componentRef = el)} />
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <Box className={classes.button}>
                                        <GradientButton fullWidth onClick={this.printOrder}>
                                            <faFingerprint/>
                                            In Đơn Hàng
                                        </GradientButton>
                                    </Box>
                                )
                            }}
                            pageStyle='@page { size: auto; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 40px !important; } }'
                            documentTitle={_id}
                            content={() => this.componentRef}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(CheckoutDialog)