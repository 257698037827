import React, { Component } from 'react'
import Post from '../components/Content/Post/Post'

export default class PostPage extends Component {
  render() {
    return (
      <Post></Post>
    )
  }
}
