import React, { Component } from 'react'
import MyFooter from '../../MyFooter/MyFooter'
import { actAddCategoryRequest, actGetCategoryRequest, actEditCategoryRequest } from '../../../redux/actions/category';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import callApi from '../../../utils/apiCaller';
import { uploadS3 } from '../../../utils/upload'
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
const queryString = require('query-string');
let token;
let id;

const override = css`
    display: block;
    margin: 0 auto;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
`;
class ActionCategory extends Component {
  constructor(props) {
    const parsed = queryString.parse(window.location.search);
    
    super(props);
    
    this.state = {
      isActive: true,
      name: parsed?.subName?parsed?.subName:'',
      desc: parsed?.subDescription?parsed?.subDescription:'',
      image: parsed?.subImage?parsed?.subImage:'',
      redirectToCategory: false,
      subCategory:[],
      img: null,
      loading: false,
      parentId:parsed?.parentId,
      subId:parsed?.subId,
      parentName:parsed?.parentName
    };
    id = this.props.id
   
  }

  async componentDidMount() {
    // const { fromNotifications } = this.props.location.state
    

    token = localStorage.getItem('_auth');
    
    if (id&& this.state.subId === undefined) {
      const res = await callApi(`categories/${id}`, 'GET', null, token);
      this.setState({
        isActive: res.data.isActive,
        name: res.data.nameCategory,
        desc: res.data.description,
        image: res.data.image,
        subCategory:res.data.subCategory
      })
    }
  }

  handleChangeImage = (event) => {
    if (event.target.files[0]) {
      const img = event.target.files[0];
      this.setState(() => ({ img }));
    }
    const output = document.getElementById('output');
    output.src = URL.createObjectURL(event.target.files[0]);
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  makeid = (length)=> {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result.toUpperCase();
}
  callSubmit = async (image,removeArray) => {
     const { isActive, name, desc , parentId ,subCategory} = this.state;
     const newImage= (image === '') ? null : image
    const newDesc = desc === '' ? null : desc;
    const newName = name === '' ? null : name;
    
    if (!id) {
      const newCategory = {
        isActive,
        nameCategory: newName,
        description: newDesc,
        image: newImage,
        subCategory
      }
      
      if (parentId!== undefined){
        newCategory.parentId = parentId
        newCategory.subId = parentId+this.makeid(10)
      }
     
      await this.props.add_category(token, newCategory);
      let that =this
      setTimeout(function(){ 
        that.setState({
          loading: false,
          redirectToCategory: true
        })
      }, 500);

      
    } else {
      const editCategory = {
        isActive,
        nameCategory: newName,
        description: newDesc,
        image: newImage,
        removeArray:removeArray,
        subCategory
      }
      if (this.state.subId!== undefined){
        editCategory.subId = this.state.subId
      }
      await this.props.edit_category(token, id, editCategory);
      let that =this
      setTimeout(function(){ 
        that.setState({
          loading: false,
          redirectToCategory: true
        })
      }, 500);
    }
  }
  handleSubmit = async (event) => {
    event.preventDefault();
   
    let { img, image } = this.state;
    this.setState({
      loading: true
    })
    var removeArray =[]
    if (img !== null && img !== image) {
      if (image.length >0){
        removeArray.push(image.slice())
      }
      image = await uploadS3("categoryImage"+Math.random(),img,token);
      this.callSubmit(image,removeArray)
      
    }else{
      await this.callSubmit(image)
    }
    
  }
  handleRemove = async (event) => {
    event.preventDefault();
   
    let { image } = this.state;
    this.setState({
      loading: true
    })
    var removeArray =[]
    if (image.length >0){
      removeArray.push(image.slice())

    }
    const editCategory = {
      nameCategory: "",
      subId:this.state.subId,
      removeArray:removeArray,
      editCategory : this.state.subId
    }
   
    await this.props.edit_category(token, id, editCategory);
     let that =this
      setTimeout(function(){ 
        that.setState({
          loading: false,
          redirectToCategory: true
        })
      }, 500);
    
    
  }


  render() {
    const { isActive, name, desc, redirectToCategory, loading, image,parentName } = this.state;

    if (redirectToCategory) {
      return <Redirect to='/categories'></Redirect>
    }
    return (
      <div className="content-inner">
        {/* Page Header*/}
        <div className='sweet-loading'>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={30}
            color={'#796aeebd'}
            loading={loading}
          />
        </div>
        <header className="page-header">
          <div className="container-fluid">
          {!id?<h2 className="no-margin-bottom">{parentName!==undefined?"Thêm danh mục con cho "+parentName:"Thêm danh mục"}</h2>:<h2 className="no-margin-bottom">Cập nhật danh mục</h2>}

          </div>
        </header>
        {/* Breadcrumb*/}
        <div className="breadcrumb-holder container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Trang chủ</a></li>
            <li className="breadcrumb-item active">Danh mục</li>
          </ul>
        </div>
        {/* Forms Section*/}
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              {/* Form Elements */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h3 className="h4">Thông tin</h3>
                  </div>
                  <div className="card-body">
                    <form className="form-horizontal" onSubmit={(event) => this.handleSubmit(event)} >
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Tên danh mục</label>
                        <div className="col-sm-9">
                          <input name="name" onChange={this.handleChange} value={name} type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Mô tả ngắn</label>
                        <div className="col-sm-9">
                          <input name="desc" onChange={this.handleChange} value={desc} type="text" placeholder="Note" className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label htmlFor="fileInput" className="col-sm-3 form-control-label">Hình ảnh (kích thước hình ảnh là 575 x 300 px)</label>
                        <div className="col-sm-9">
                        <input type="file" onChange={this.handleChangeImage} className="form-control-file" />
                        <div className="fix-cart">
                          <img src={image || 'http://via.placeholder.com/575x300'} id="output" className="fix-img" alt="avatar" />
                          </div> 
                       </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Trạng thái</label>
                        <div className="col-sm-9">
                          <div className="i-checks">
                            <input type="checkbox"
                              onChange={this.handleChange}
                              name="isActive"
                              checked={isActive}
                              className="checkbox-template" />
                            <label htmlFor="checkboxCustom1"></label>
                          </div>
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <div className="col-sm-4 offset-sm-3">
                        {this.state.subId!==undefined?<button onClick={this.handleRemove.bind(this)} type="remove" className="btn btn-secondary" style={{ marginRight: 2,backgroundColor:"red" }}>Xóa</button>:null}
                          
                          <button type="submit" className="btn btn-primary">Lưu danh mục</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/}
        <MyFooter></MyFooter>
      </div>
    )
  }
}


const mapDispatchToProps = (dispatch,props) => {
  return {
    props,
    add_category: (token, newCategory) => {
      dispatch(actAddCategoryRequest(token, newCategory))
    },
    get_category: (token, id) => {
      dispatch(actGetCategoryRequest(token, id))
    },
    edit_category: (token, id, data) => {
      dispatch(actEditCategoryRequest(token, id, data))
    }
  }
}
export default connect(null, mapDispatchToProps)(ActionCategory)