import axios from 'axios';
import * as Config from '../constants/Config';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
toast.configure()

export const  getShopData = async function (token) {
    var shop_data = await callApi(`admin-shop-data`, 'GET', null, token);
    
    if (shop_data.status === 200){
      
      let introBanners = shop_data.data.introBanners;
      let places = shop_data.data.places;
      let categories = shop_data.data.categories.categories;
     
      shop_data.data.logoTop =  shop_data.data.logoTop.replace("https://s3-ap-southeast-1.amazonaws.com/", "https://dcn.ogis.vn/");
      shop_data.data.logoFooter = shop_data.data.logoTop.replace("https://s3-ap-southeast-1.amazonaws.com/", "https://dcn.ogis.vn/");
      shop_data.data.introBanners = await introBanners.map((item,index) => {
        item.image = item.image.replace("https://s3-ap-southeast-1.amazonaws.com/", "https://dcn.ogis.vn/");
        return item
      })
      shop_data.data.places = await places.map((item,index) => {
        item.image = item.image.replace("https://s3-ap-southeast-1.amazonaws.com/", "https://dcn.ogis.vn/");
        return item
      })
      shop_data.data.categories.categories = await categories.map((item,index) => {
        item.image = item.image.replace("https://s3-ap-southeast-1.amazonaws.com/", "https://dcn.ogis.vn/");
        return item
      })
      
      return shop_data.data
    }else{
      return shop_data.status
    }
  
  
}
export const  getProducts = async function (token) {
  var response = await callApi(`admin-products`, 'GET', null, token);
  let results = response.data?.results.map((item,index) => {

    item.image = item.image.replace("https://s3-ap-southeast-1.amazonaws.com/", "https://dcn.ogis.vn/");
    
    var d = new Date(item.until);
    
    var tcv = d.getFullYear()+"-"+("0"+(d.getMonth()+1)).slice(-2)+"-"+("0" + d.getDate()).slice(-2)+"T23:59:59"
    const isDiscount = validateDiscount(tcv)
    var newPrice = item.fPrices[0].price
    if (isDiscount){
      newPrice = 0.01*item.fPrices[0].price * (100-item.salePrice)
    }
    let itemCovert = {
    "categoryId":item.categoryId,
    "sku":item.sku,
    "subCategoryId":item.subCategoryId,
    "subCategoryName":item.subCategory.nameCategory,
    "categoryName":item.categories.nameCategory,
      "vid":index,
      "name" : item.nameProduct,
      "category" : [
          item.categoryId,item.subCategoryId
      ],
      "brands" : [
          item.producerId
      ],
      "shortDesc" : item.description,
      "id" : item.id,
      "_id" : item.id,
      "price":item.fPrices[0].price,
      "newPrice":newPrice,
      "unit":item.fPrices[0].unit,
      "smPictures" : item.gallery,
      "pictures" : [
        item.image
      ],
      "ratings" : item.ratings,
      "reviews" : item.reviews,
      "stock": item.numberAvailable+999,                            
      "new": item.new,                                      
      "top": item.top,       
      "size": item.size,
      "salePrice": item.salePrice,
      "discount": item.discount && isDiscount?true:false,
      "until": tcv,
      "variants": item.variants,
      "fPrices":item.fPrices
    };
      return itemCovert
        
  });
  return results
}
export const  createOrder = async function (body,token) {
  var response = await callApi(`admin-orders`, 'POST', body, token);
  return response
}
async function callApiCDN(endpoint, method = 'GET', body, token) {
  try {
    let data;
    if (token !== undefined && token !== null && token !== '') {
      
      data =  axios({
        method: method,
        url: `${Config.API_URL_CDN}/${endpoint}`,
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '+ token,
      
      },
        data: body
      });
      return data;
    } else {
      
      data =  axios({
        method: method,
        url: `${Config.API_URL_CDN}/${endpoint}`,
    
        data: body
      });
      return data;
    }
  }
  catch (err) {
    console.log(JSON.stringify(err))
  }
}
function validateDiscount(until){
  let now =  new Date()
  //  now.setDate(new Date().getDate() +6)
  var validate = false
  var d = new Date(until);
  if(now.getTime() < d.getTime()){
      validate = true
  
  }
  return validate
}
export default async function callApi(endpoint, method = 'GET', body, token) {
  try {
    let data;
    
    if (token !== undefined && token !== null && token !== '') {
    
      data = await axios({
        method: method,
        url: `${Config.API_URL}/${endpoint}`,
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '+ token,
      },
        data: body
      });
      return data;
    } else {
     
      data = await axios({
        method: method,
        url: `${Config.API_URL_PUBLIC}/${endpoint}`,
        data: body
      });
      return data;
    }
  }
  catch (err) {
    
    if (err.response && err.response.data) {
     
      if (err.response.data.code === 401 && err.response.data.message !== "incorrect Username or Password"){
        localStorage.removeItem('_auth');
        localStorage.removeItem("dashBoardId");
        window.location.href = "/login";
      }else{
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${err.response.data.message}`
        })
      }

      
    }  
    else if (err.message === "Request failed with status code 403") {
     
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Bạn không có quyền này. Hãy liên hệ với Quản Lý của bạn.'
      })
    }
    else {
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Can not connect to server!'
      })
    }
  }
  return "";
}