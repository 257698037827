import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import colors from "../../constants/colors";
import GradientButton from "../Custom/GradientButton";
import CartItem from "./CartItem";
import CartItemGeneral from "./CartItemGeneral";

import Grid from "@material-ui/core/Grid";
import CancelIcon from '@material-ui/icons/Cancel';
import Button from "@material-ui/core/Button";
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';
const override = css`
    display: block;
    margin: 0 auto;
    position: fixed;
    top: 40%;
    left: 80%;
    transform: translate(-50%, -50%);
    z-index: 9999;
`;
const styles = (theme) => ({
    root: {
        height: 'calc(100% - 30px)',
        padding: 15,
        position: 'relative',
        background: colors.white,
        borderRadius: 20
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        color: colors.dark,
        textAlign: 'center',
        height: "44px",
        marginBottom:20,
        marginTop:20
    },
    content: {
        height: '70%'
    },
    carts: {
        marginTop:20,
        maxHeight: '65%',
        overflow: 'scroll',
        overflowX: 'hidden',
        '& td': {
            fontSize: 12,
            fontWeight: 600
        }
    },
    noCart: {
        textAlign: 'center',
        marginTop: 30
    },
    qty: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 0,
        paddingRight: 0
    },
    calculation: {
        background: colors.white,
        position: 'absolute',
        padding: 15,
        
        bottom: 0,
        left: 0,
        right: "1rem",
        borderRadius: 20
    },
    calcItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 14,
        marginBottom: 10,
        borderBottom: `1px solid ${colors.light}`
    },
    total: {
        fontSize: 18,
        fontWeight: 700,
        color: colors.dark
    },
    paper: {
        height: 200
    },
    cancel: {
        background: colors.grey,
        color: colors.white
    }
})

class Cart extends React.Component {
    render() {
        const { classes, items, subtotal, tax, discount, grandtotal,
            changeQty,onChangePrice,onChangeName, deleteItem, payClick, cancelClick, disabled } = this.props

        return (
            <Box className={classes.root}>
            <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={30}
                            color={'#f40057'}
                            loading={disabled}
                        />
                <Typography className={classes.title}>Đơn Hàng</Typography>
                <Grid container spacing={3} style={{ fontWeight:"bold"}}>
                <label style={{width: "47%",
    textAlign: "center",left:10}}>Sản Phẩm<sup style={{color:"#f40057"}}>({items.length})</sup></label>
                            <label style={{width: "22%",
    textAlign: "left",left:0}}>Số Lượng</label>
                            <label style={{width: "30%",
    textAlign: "center",right:10}}>Thành Tiền</label>
                    </Grid>
                <Box className={classes.carts}>
                    <Box>

                    
                        { items && items.length === 0 ? (
                            <Box className={classes.noCart}>
                                Chưa Có Sản Phẩm Nào
                            </Box>
                        ) : ''}
                        {items.map((item, i) => (
                            item.typeProduct && item.typeProduct==="general"?
                            <CartItemGeneral
                                key={i}
                                {...item}
                                changeQty={changeQty}
                                onChangePrice={onChangePrice}
                                onChangeName={onChangeName}
                                deleteItem={deleteItem}
                            />:
                            <CartItem
                                key={i}
                                {...item}
                                changeQty={changeQty}
                                deleteItem={deleteItem}
                            />

                        ))}
                    </Box>
                </Box>
                <Box className={classes.calculation}>
                    <Box className={classes.calcItem}>
                        <Typography variant="body2">Tổng Tiền Hàng</Typography>
                        <Typography variant="body2">{parseInt(subtotal).toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } ) +"đ"}</Typography>
                    </Box>
                    { discount !== 0 && (
                        <Box className={classes.calcItem}>
                            <Typography variant="body2">Giảm Giá</Typography>
                            <Typography variant="body2">{parseInt(discount).toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } ) +"đ"}</Typography>
                        </Box>
                    )}
                    { tax !== 0 && (
                        <Box className={classes.calcItem}>
                            <Typography variant="body2">Thuế</Typography>
                            <Typography variant="body2">{parseInt(tax).toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } ) +"đ"}</Typography>
                        </Box>
                    )}
                    <Box className={classes.calcItem}>
                        <Typography className={classes.total}>Tổng Tiền Thanh Toán</Typography>
                        <Typography className={classes.total}>{parseInt(grandtotal).toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } ) +"đ"}</Typography>
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={6} lg={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                className={classes.cancel}
                                disabled={items.length === 0 || disabled?true:false}
                                startIcon={<CancelIcon/>}
                                onClick={cancelClick}>
                                Hủy
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <GradientButton
                                fullWidth
                                onClick={payClick}
                                disabled={items.length === 0 || disabled? true:false}>
                                Thanh Toán
                            </GradientButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        );
    }
}

export default withStyles(styles)(Cart)