export const currencyFormatVND = num => {
 
    num = num + ","
    if (num){
      num = num.replaceAll(",", "");
      if (num.length === 0) num = "0"
      return (
        parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      );
    }else return "0"
  
  };
  export const currencyVNDToNum = num => {
    num = num.toString()
    if (num){
      num = num.replaceAll(/,/g, "");
      return num*1
    }else return 0
  
  };