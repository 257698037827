import React, { Component } from 'react'
import Pos from '../components/Content/Pos/Transaction'

export default class POS extends Component {
  render() {
    return (
      <Pos></Pos>
    )
  }
}
