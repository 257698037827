import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import callApi from '../../utils/apiCaller';
let token;
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
    
    }
  }

  async componentDidMount() {
    token = localStorage.getItem('_auth');
    if (token) {
      
    } else {
      this.setState({
        redirect: true
      })    
    }
  }

  renderAdmin(){
    const { user } = this.props;
    return <nav className="side-navbar">
    <div className="sidebar-header d-flex align-items-center">
      <div className="title">
            <h3 >{user && user.name ? user.name : null}</h3>
            <h1 className="h4">{user.role.description }</h1>
     </div>
    </div>      
    <span className="heading">QUẢN LÝ CỬA HÀNG</span>
    
      <ul className="list-unstyled">
        <li><Link to="/"> <i className="icon-home" />Trang chủ </Link></li>
        <li><Link to="/pos"> <i className="icon icon-bill" />POS<sup style={{color:"red"}}>Beta</sup></Link></li>
        <li><Link to="/orders"> <i className="icon icon-bill" />Đơn hàng</Link></li>
        <li><Link to="/products"> <i className="icon icon-website" />Sản phẩm</Link></li>
        <li><Link to="/categories"> <i className="icon-interface-windows" />Danh mục</Link></li>
        <li><Link to="/producers"> <i className="icon icon-list-1" />Thương hiệu</Link></li>
        <li> <Link onClick={()=> {

var width  = 1024;
var height = 1024;
var left   = 460;
var top    = 100;
var params = 'width='+width+', height='+height;
params += ', top='+top+', left='+left;
params += ', directories=no';
params += ', location=no';
params += ', menubar=no';
params += ', resizable=no';
params += ', scrollbars=no';
params += ', status=no';
params += ', toolbar=no';
window.open("https://ogis.vn/blog/listing/", "OGIS", params);
}} to="#"> <i className="icon-search" onClick={()=> window.open("https://ogis.vn/blog/listing/", "_blank")} />Hướng dẫn</Link></li>
   

        {/* <li><Link to="/discounts"> <i className="icon icon-check" />Discount</Link></li> */}
       
        
        
       
        
        {(user.role.nameRole === 'superadmin') ?
          <div>
            <li><Link to="/roles"> <i className="icon icon-bars" />Phân quyền</Link></li>
            <li><Link to="/ratings">  <i className="icon icon-check" />Đánh Giá</Link></li>
            <li> <Link to="/contacts"> <i className="icon-mail" />Liên hệ </Link></li>
          </div> 
          :null
        }
        
       
   
      </ul>
      
      
    
      {(user.role.nameRole === 'admin'||user.role.nameRole === 'superadmin'||user.role.nameRole === 'staff') ?<div>
      <span className="heading">QUẢN LÝ WEBSITE</span>
    <ul className="list-unstyled">
      <li> <Link to={`/edit-shop_infor/${user.companyid}/shop_infor`}> <i className="icon-website" />Thông Tin Cửa Hàng<sup style={{color:"red", marginLeft:"5px"}}><b>SEO</b></sup></Link></li>
      <li> <Link to={`/edit-shop_banner/${user.companyid}/shop_banner`}> <i className="icon-picture" />Giao diện Website</Link></li>
      <li> <Link to={`/edit-shop_service/${user.companyid}/shop_service`}> <i className="icon-rss-feed" />Dịch Vụ Khách Hàng</Link></li>
      <li> <Link to={`/edit-shop_about/${user.companyid}/shop_about`}> <i className="icon-page" />Giới Thiệu Cửa Hàng<sup style={{color:"red", marginLeft:"5px"}}><b>SEO</b></sup></Link></li>
      <li> <Link to={`/edit-seo_top_google/${user.companyid}/seo_top_google`}> <i className="icon-flask" />SEO WEBSITE<sup style={{color:"red", marginLeft:"5px"}}><b>SEO</b></sup></Link></li>
      <li><Link to="/posts"> <i className="icon icon-padnote" />Bài viết</Link></li>
      {(user.role.nameRole === 'admin'||user.role.nameRole === 'superadmin') ?<li><Link to="/users"> <i className="icon icon-user" />Nhân Viên</Link></li>:null}
       </ul>

    </div>:null}
  </nav>
  }
  render() {
    const { user } = this.props;
    if (user.role=== undefined || user.role=== null){
      return(<div></div>)
    }else
    return (
     
      this.renderAdmin()
    )
  }
}

const mapStateToProps = (state) => {

  return {
    nameRole: state.nameRole,
    user:state.infoMe
  }
}

export default connect(mapStateToProps, null)(NavBar)